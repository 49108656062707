import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { startSessionAsync } from '../../providers/application/application.actions';
import { useApplicationDispatch } from '../../providers/application/application.provider';

const StartSession = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useApplicationDispatch();

  useEffect(() => {
    startSessionAsync(location.search, dispatch, () => history.push('/Request'));
  }, []);

  return <div />;
};

export default StartSession;
