import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import useStyles from './header.styles';
import headerImageMobile from '../../assets/images/header_image_dsk_440px.png';
import headerImageTablet from '../../assets/images/header_image_dsk_768px.png';
import headerImageDesktop from '../../assets/images/header_image_dsk_1336px.png';
import DskLogo from '../../assets/images/dsk_logo.svg';

function NavMenu() {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleLogoClick = () => {
    window.location.reload(true);
  };

  return (
    <Container className={`header-container ${classes.headerContainer}`}>
      <picture>
        <source srcSet={headerImageMobile} media='(max-width: 440px)' />
        <source srcSet={headerImageTablet} media='(min-width: 440px) and (max-width: 768px)' />
        <source srcSet={headerImageDesktop} media='(min-width: 768px)' />
        <img src={headerImageDesktop} className={classes.headerImage} alt='header' />
      </picture>
      <Container className={classes.headerContentContainer}>
        <Grid container className={classes.logoContainer}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={DskLogo}
            className={classes.dskLogoHeader}
            alt='logo'
            onClick={handleLogoClick}
          />
          <div>
            <Box
              component='a'
              href='https://dskbank.bg/%D0%B7%D0%B0-%D0%B1%D0%B0%D0%BD%D0%BA%D0%B0-%D0%B4%D1%81%D0%BA'
              target='_blank'
              rel='noopener noreferrer'
              display={{ xs: 'none', sm: 'inline' }}
              className={classes.topMenuItemLeft}
            >
              {t('header.about-dsk-bank')}
            </Box>
            <Box
              component='span'
              display={{ xs: 'none', sm: 'inline' }}
              className={classes.headerDivider}
            >
              |
            </Box>
            <Box
              element='div'
              display={{ xs: 'block', sm: 'inline' }}
              className={classes.topMenuItemCenter}
            >
              0700 10 375 / *2375
            </Box>
          </div>
        </Grid>
      </Container>
    </Container>
  );
}

export default NavMenu;
