import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './request-success.styles';
import SuccessIcon from '../../../assets/images/success_icon.svg';
import { signOut } from '../../../providers/application/application.actions';

const RequestSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    signOut();
  }, []);

  return (
    <Grid container spacing={2} justify='center'>
      <Grid className={classes.gridItem} item xs={12}>
        <img src={SuccessIcon} alt='' />
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {t('request-success.completed-request')}
        </Typography>
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h6' variant='h6'>
          {t('request-success.completed-request-subtitle.line1')}
          <br />
          {t('request-success.completed-request-subtitle.line2')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RequestSuccess;
