import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  personalDataConsentLink: {
    color: '#52ae30',
  },
  remoteFinancialServicesConsentLink: {
    color: '#52ae30',
  },
  foreignNumberText: {
    color: '#f44336',
    marginLeft: '12px',
  },
}));

export default useStyles;
