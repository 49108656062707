import React from 'react';

function PersonalDataConsent() {
  return (
    <div className='consent'>
      <h3 align='center'>
        ИНФОРМАЦИЯ ОТНОСНО ОБРАБОТВАНЕТО НА ЛИЧНИ ДАННИ НА ФИЗИЧЕСКИ ЛИЦА ОТ „БАНКА ДСК“ АД И
        СЪГЛАСИЯ ЗА ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ
      </h3>

      <div>
        <p>
          1.1. &bdquo;Банка ДСК&rdquo; АД е търговско дружество, регистрирано в Търговския регистър
          и регистъра на ЮЛНЦ към Агенция по вписванията с ЕИК 121830616 със седалище и адрес на
          управление: гр. София, 1000, ул. &bdquo;Московска&rdquo; № 19. тел: *2375 / 0700 10 375;
          факс: (+359 2) 9076 499; e-mail: call_center@dskbank.bg,; Интернет страница
          www.dskbank.bg. В качеството си на Администратор на лични данни, осъществява своята
          дейност при стриктно спазване на изискванията на Регламент (ЕС) 2016/679 на Европейския
          парламент и на Съвета относно защитата на физическите лица във връзка с обработването на
          лични данни и относно свободното движение на такива данни и Закона за защита на личните
          данни. Банка ДСК е част от OTP Group, предоставяща финансови услуги на територията
          Централна и Източна Европа. Част от Групата в България са дружествата ДСК Родина, ДСК
          Транс Секюрити, ДСК Лизинг, ОТП Лизинг, ДСК Управление на активи, ДСК Дом, ДСК Турс, ОТП
          Факторинг България и ДСК Мобайл.
        </p>
        <p>
          Данни за контакт с Длъжностно лице по защита на личните данни: e-mail: DPO@dskbank.bg.
        </p>
        <p>1.2. Настоящата информация се прилага за обработване на лични данни на:</p>
        <p>
          а) лица, които сключват с Банката договор за банково финансиране и/или договор за неговото
          обезпечаване (поръчителство, залог, ипотека), или които подават искане за сключване на
          такъв договор, както и лица, които получават персонализирани и неперсонализирани оферти;
        </p>
        <p>
          б) лица, които сключват договор за продукт и/или услуга на Банката, различен от този по б.
          &bdquo;а&ldquo;, или които подават искане за сключване на такъв договор, вкл. искане за
          получаване на персонализирани и неперсонализирани оферти;
        </p>
        <p>
          в) лица, които сключват с Банката договори с еднократно изпълнение, например по които се
          предоставят еднократни платежни услуги;
        </p>
        <p>г) лица, които подават жалба/искане/предложение, без да са клиенти на Банката;</p>
        <p>
          д) продавач на недвижим имот &ndash; при финансиране на купувача на имота, който ще бъде
          обезпечение по договора за кредит;
        </p>
        <p>е) лица, чиито задължения към друг кредитор се рефинансират с кредит от Банката;</p>
        <p>
          ж) лица, които са подали искане за проверка за наличие на задължения за местни данъци и
          такси;
        </p>
        <p>з) наследници на лицата по горните точки;</p>
        <p>и) представляващи лицата (физически и юридически) по горните точки;</p>
        <p>
          2.1.1. Банка ДСК обработва следните лични данни и категории лични данни за лица в
          хипотезите на 1.2., от б. &bdquo;в&ldquo; до &bdquo;з&ldquo;:
        </p>
        <p>
          а) имена, идентификационен номер, дата и място на раждане, гражданство, вид, номер и копие
          на документ за самоличност;
        </p>
        <p>б) данни за контакт, например: адреси, стационарен/мобилен телефон, електронна поща;</p>
        <p>в) демографски характеристики, например: пол, възраст, местоживеене;</p>
        <p>
          г) данни за физическата идентичност &ndash; лицеви изображения, глас, почеркови признаци.
        </p>
        <p>д) данни за икономическото състояние, например наличие на кредитни задължения;</p>
        <p>
          е) данъчна и осигурителна информация, например наличие на публични задължения и
          месторабота.
        </p>
        <p>
          2.1.2. За лицата по т. 1.2. б. &bdquo;а&ldquo; и &bdquo;б&ldquo;, освен данните по т.
          2.1.1., Банката обработва и следните техни данни:
        </p>
        <p>а) местоположение;</p>
        <p>б) данни за месторабота, длъжност/професия;</p>
        <p>
          в) данни за студент, например: учебно заведение, факултет, факултетен номер, професионално
          направление, специалност, курс, редовна/задочна форма на обучение;
        </p>
        <p>
          г) данни за и от ползвани и отказани продукти/услуги на Банката, както и на трети лица, с
          които Банката има сключен договор;
        </p>
        <p>
          д) за лица, които са заявили желание или ползват платежни услуги по предоставяне на
          информация за сметка и за иницииране на плащания - финансова информация за сметки,
          движения по сметки и наличности при други доставчици на платежни услуги.
        </p>
        <p>
          2.2. За целите на банково финансиране, както и за директен маркетинг на продукти и услуги
          на Банката, освен данните по т. 2.1.1. и 2.1.2., Банка ДСК обработва и следните данни на
          физическите лица по т. 1.2. б. &bdquo;а&ldquo; и &bdquo;б&ldquo;:
        </p>
        <p>
          а) данни за икономическо състояние, с които Банка ДСК разполага във връзка с ползвани
          продукти и услуги на Банката, както и данни получени от информационните масиви на НОИ,
          ЦКР, НАП, /само при наличие на съгласие на клиента за това/ например: доходи, притежавано
          имущество, кредитна задлъжнялост; наличие на публични задължения;
        </p>
        <p>б) данни за гражданско състояние, например: семейно положение, свързани лица.</p>
        <p>
          2.3. За представляващите физически и юридически лица Банка ДСК обработва следните лични
          данни:
        </p>
        <p>а) име и идентификационен номер,</p>
        <p>б) дата и място на раждане, гражданство,</p>
        <p>в) данни от документ за самоличност;</p>
        <p>г) данни за контакт, например: адреси, стационарен/мобилен телефон, електронна поща;</p>
        <p>д) местоположение;</p>
        <p>е) данни за месторабота, длъжност/професия;</p>
        <p>
          ж) данни за физическата идентичност &ndash; лицеви изображения, глас, почеркови признаци.
        </p>
        <p>
          2.4. Банка ДСК обработва следните лични данни на действителните собственици на бизнес
          клиент: име, идентификационен номер, дата и място на раждане, гражданство, документ за
          самоличност, постоянен адрес.
        </p>
        <p>
          3.1. Личните данни на физическите лица по т.1.2., б. &bdquo;а&ldquo; ,&bdquo;б&ldquo; и
          &bdquo;з&ldquo; се обработват за целите на:
        </p>
        <p>
          а) сключване с Банката и изпълнение на договор, по който физическото лице е
          страна/представляващ друго лице; изпълнение на договор, по който Банката или физическото
          лице/представляваният встъпва/замества в правата и/или задълженията друго лице, както и за
          действия, предхождащи и обуславящи сключването на договор;
        </p>
        <p>
          б) упражняване на законови права и задължения на Банката във връзка със сключването и
          изпълнението на договорите по б. &bdquo;а&ldquo;;
        </p>
        <p>
          в) реализиране на права и интереси на Банката, които имат обосновано преимущество пред
          интересите на физическите лица, вкл. извършване на директен маркетинг и профилиране, чрез
          прочувания относно предлагани и/или ползвани продукти и услуги, както и чрез отправяне на
          предложения по телефон, поща или друг директен начин, на продукти и услуги на Банката, за
          които се приема, че клиентът би могъл да очаква предложения, отчитайки използваните от
          него продукти и услуги на Банката.
        </p>
        <p>
          г) директен маркетинг за продукти и услуги на Банката, извън случаите на б.
          &bdquo;в&ldquo;, както и продукти и услуги на трети лица, вкл. Дъщерни дружества на
          Банката, предлагани от нея по силата на сключен с тези лица договор &ndash; само със
          съгласие на физическото лице.
        </p>
        <p>
          д) автоматичен обмен на финансова информация по глава XVI, раздел IIIа от
          Данъчно-осигурителния процесуален кодекс, като процесът включва автоматизирана обработка
          на лични данни, чрез прилагане на процедурите за комплексна проверка по ДОПК.
        </p>
        <p>
          3.2. Във връзка със сключване на договор за банково финансиране и/или договор за неговото
          обезпечаване (поръчителство, залог, ипотека), освен за целите по т. 3.1., данните на
          лицата по т. 1.2. б. &bdquo;а&ldquo;, б. &bdquo;б&ldquo; и б. &bdquo;з&ldquo; се
          обработват и за целите на:
        </p>
        <p>
          а) разглеждане на искане за ползване на финансиране, оценка на обезпечението, извършване
          на анализ на кредитоспособността, който включва, наред с други проверки, искането и
          получаването на данни от официални за страната регистри, като например регистри,
          поддържани от НОИ, ЦКР, НАП, от бази данни на Банката и др., и други подготвителни
          действия за сключване на договор за финансиране и договори за учредяване на обезпечения;
        </p>
        <p>б) учредяване и подновяване на обезпеченията по договора;</p>
        <p>
          в) сключване и поддържане на имуществена застраховка, когато се изисква такава, съгласно
          договора, или когато Банката по собствена преценка сключи застраховка на поетия риск.
        </p>
        <p>
          3.3. Личните данни на представляващите по т. 1.2, б. &bdquo;и&ldquo; се обработват за
          целите по т. 3.1., б. &bdquo;а&ldquo;, &bdquo;б&ldquo; , &bdquo;д&ldquo; , за реализиране
          на права и интереси на Банката, които имат обосновано преимущество пред интересите на
          физическите лица, а в случаите на сключване на договор за банково финансиране и/или
          договор за неговото обезпечаване (поръчителство, залог, ипотека) от името на
          представляваното лице - и за целите на:
        </p>
        <p>
          а) разглеждане на искане за ползване на финансиране и други подготвителни действия за
          сключване на договор за финансиране и договори за учредяване на обезпечения;
        </p>
        <p>б) учредяване и подновяване на обезпеченията по договора;</p>
        <p>
          в) сключване и поддържане на имуществена застраховка, когато се изисква такава, съгласно
          договора, или когато Банката по собствена преценка сключи застраховка на поетия риск.
        </p>
        <p>
          3.4. Банката обработва лични данни на физически лица по т.1.2., от б. &bdquo;в&ldquo; до
          &bdquo;ж&ldquo;, за следните цели:
        </p>
        <p>
          а) сключване и изпълнение на договор с Банката с еднократно изпълнение, по който
          физическото лице, за което се отнасят данните, е страна или е сключило в качеството на
          представляващ друго лице, вкл. за действия, предхождащи и обуславящи сключването на такъв
          договор;
        </p>
        <p>
          б) извършване на подготвителни действия за сключване на договор за банково финансиране за
          рефинансиране на кредитни задължения на физическото лице, чиито данни се обработват;
        </p>
        <p>
          в) изискване и получаване на данни от официални за страната регистри (напр. на НАП за
          наличие на публични задължения), отнасящи се до собственика на недвижим имот, например при
          финансиране на купувача на имота, като имотът ще бъде и обезпечение по договора за кредит;
        </p>
        <p>г) проучване на поставени в жалба/искане/предложение въпроси и изготвяне на отговор;</p>
        <p>д) изпълнение на законови права и задължения на Банката.</p>
        <p>
          3.5. Личните данни на действителните собственици на бизнес клиент се обработват за целите
          на тяхната идентификация и прилагане на мерките срещу изпирането на пари и финансирането
          на тероризма, в изпълнение на законови задължения на Банката.
        </p>
        <p>
          3.6. С цел охрана и при спазване на нормативните изисквания се извършва видеонаблюдение в
          поделенията.
        </p>
        <p>4. Обработването на личните данни се извършва на основание:</p>
        <p>
          а) сключен договор с Банката или с трето лице, чрез Банката, по който физическото лице е
          страна или представляващ друго лице, вкл. за действия, предхождащи и обуславящи
          сключването на договор и предприети по искане на физическото лице; или
        </p>
        <p>б) предоставено съгласие на физическото лице, или</p>
        <p>
          в) реализиране на права и интереси на Банката, които имат обосновано преимущество пред
          интересите на физическите лица, или
        </p>
        <p>г) изпълнение на законови задължения на Банката.</p>
        <p>
          5.1. Личните данни на физическите лица по т. 1.2., б. &bdquo;а&ldquo;, б. &bdquo;б&ldquo;
          и &bdquo;з&ldquo; , както и данните на представляващи физически и юридически лица,
          събирани и обработвани от Банката, могат да бъдат предоставяни на следните категории
          получатели на лични данни:
        </p>
        <p>
          а) лица, на които са възложени действия по изработване, печатане, комплектоване, доставка
          (вкл. чрез SMS/Viber съобщения или по електронен път) на писмена кореспонденция и/или
          информационни материали на Банката;
        </p>
        <p>б) лица, на които са възложени действия по издаване на банкови карти;</p>
        <p>
          в) лица, с които Банката е сключила договор за съвместно създаване и обслужване на
          продукти и/или предоставяне на услуги;
        </p>
        <p>
          г) лица, чиито услуги Банката използва при предоставяне на инвестиционни и допълнителни
          услуги на клиенти;
        </p>
        <p>
          д) лица и институции, с които Банката е сключила договор за гарантиране на портфейли от
          кредити и/или отделни кредити на клиенти;
        </p>
        <p>
          е) лица, на които е възложено по силата на договор с Банката да й оказват съдействие във
          връзка с управлението и събирането на вземанията й;
        </p>
        <p>ж) лица, на които Банката предлага да продаде вземанията си;</p>
        <p>
          з) лица, на които Банката е възложила да връчва уведомления за предсрочна изискуемост по
          договори за кредит или друга форма на финансиране (напр. нотариуси, ЧСИ);
        </p>
        <p>и) външни контактни центрове;</p>
        <p>
          й) търговци, които по силата на договор с Банката посредничат при предоставянето на
          кредитни или други банкови продукти и услуги;
        </p>
        <p>
          к) други дружества от корпоративната група, към която Банката принадлежи (OTP Group), ако
          е необходимо за целите на вземане на решение, администриране и контрол във връзка с
          предоставянето и изпълнението на услугите, предлагани от Банката, или дружествата;
        </p>
        <p>
          л) лица, на които във връзка с обработването за посочените в т. 3 цели, Банката е
          възложила обработването на личните данни по организационни причини, различни от посочените
          по-горе, например: разработване и поддръжка на системи на Банката; съхранение на данните;
          контрол на достъпа до помещенията на Банката и др.;
        </p>
        <p>
          м) органи, институции, регулирани пазари, на които Банката е член, както и други лица -
          във връзка с реализиране и защита правата и интересите на Банката или, на които Банката е
          длъжна да предостави лични данни по силата на законова разпоредба;
        </p>
        <p>
          н) платежни организации и системи, обслужващи безналични плащания и преводи, включително и
          с платежни инструменти, както и външни доставчици, през приложения на които се
          дигитализират банкови карти, издадени от Банка ДСК, когато субектът на данни е пожелал
          услугата по дигитализиране.;
        </p>
        <p>
          о) на доставчици на платежни услуги по предоставяне на информация по сметка, доставчици на
          платежни услуги по иницииране на плащания и доставчици, предоставящи платежна услуга по
          потвърждение за наличие на средства при използване на платежен инструмент, които се
          интегрират към системите на Банката по предвидения законов ред за това;
        </p>
        <p>
          п) застрахователи, с които Банката има сключен договор, и техни партньори с цел сключване,
          поддържане и реализиране на имуществена застраховка, както и лица, на които Банката
          възлага оценка на обезпечения.
        </p>
        <p>
          5.2.1. Личните данни на лицата по т. 1.2., от б. &bdquo;в&ldquo; до &bdquo;ж&ldquo;,
          събирани и обработвани от Банката, могат да бъдат предоставяни на следните категории
          получатели на лични данни:
        </p>
        <p>
          a) органи, институции, регулирани пазари, на които Банката е член, както и други лица -
          във връзка с реализиране и защита на права и интереси на Банката (напр. помирителни
          комисии, съд) или, на които Банката е длъжна да предостави лични данни по силата на
          законова разпоредба;
        </p>
        <p>
          б) лица, на които са възложени действия по изработване, печатане, комплектоване, доставка
          (вкл. чрез SMS/Viber съобщения или по електронен път) на писмена кореспонденция;
        </p>
        <p>в) външни контактни центрове;</p>
        <p>
          г) други дружества от корпоративната група, към която Банката принадлежи (OTP Group), ако
          е необходимо за целите на вземане на решение, администриране и контрол във връзка с
          предоставянето и изпълнението на услугите, предлагани от Банката, или дружествата;
        </p>
        <p>
          д) платежни организации и системи, обслужващи безналични плащания и преводи, включително и
          с платежни инструменти, .
        </p>
        <p>e) лица, на които Банката възлага извършването на оценки на недвижими имоти.</p>
        <p>
          5.2.2. В зависимост от конкретната хипотеза, личните данни на физическите лица по т. 1.2.,
          от б. &bdquo;в&ldquo; до &bdquo;з&ldquo; могат да бъдат предоставени на Банка ДСК от
          самите субекти на данни или чрез следните групи трети лица:
        </p>
        <p>
          а) личните данни на физическите лица, чиито задължения ще бъдат рефинансирани, се
          получават чрез кредитоискателя, от кредитора на лицата.
        </p>
        <p>
          б) личните данни на физическите лица, получатели на средства в Банката (напр. при
          изплащане на дивиденти, аренди, обезщетения и др.), се получават от наредителя на превода,
          с който Банката е в договорни отношения за предоставяне на услуги, свързани с изплащане на
          дължими суми на получателя.
        </p>
        <p>
          в) личните данни на наследници на клиенти на Банката могат да бъдат получени от други
          наследници, нотариуси, изпълнители на завещания, органи ангажирани със събирането на
          вземания на банката в рамките на техните законови правомощия.
        </p>
        <p>
          г) личните данни на физическите лица по т. 1.2., б. &bdquo;и&ldquo; могат да бъдат
          получени от институции или юридически лица, предлагащи достъп до публично достъпни
          регистри като Търговски регистър и регистър на юридическите лица с нестопанска цел и други
          подобни.
        </p>
        <p>
          6. За целите на преценяване от Банката на възможностите за ползване на продукти и услуги
          на Банката, личните данни на лицата могат да бъдат предмет на автоматизирана обработка, в
          резултат на която се взема автоматизирано решение. Автоматизираната обработка е и начин за
          оценка на кредитоспособността на лицата. Тя включва извършване на различни проверки,
          включително в официални за страната регистри, както и бази данни на Банката, които, на
          база предварително зададени критерии, водят до положително или отрицателно решение за
          ползване на банков продукт или услуга. Този тип обработване на данните е необходимо и за
          целите за изготвяне на маркетингови оферти за ползване на банкови продукти.
        </p>
        <p>
          Лични данни на лицата могат да бъдат предмет на автоматизирана обработка и във връзка с
          прилагането на мерки срещу изпиране на пари и финансиране на тероризъм. Този тип
          обработване е необходим за спазване на изискванията на приложимото законодателство в тази
          област и води до вземане на решение за встъпване или прекратяване на делови
          взаимоотношения и предотвратяване на измами.
        </p>
        <p>
          При приложено автоматизирано вземане на решение, лицата по т. 1.2., б. &bdquo;а&ldquo; и
          б. &bdquo;б&ldquo; имат право да изразят гледната си точка по решението, да го оспорят,
          както и да поискат човешка намеса в процеса по вземане на решение.
        </p>
        <p>
          7.1. Предоставянето на личните данни е доброволно, когато тези данни са необходими за
          сключването на договор с Банката. В случай че данните не бъдат предоставени, Банката няма
          да бъде в състояние да предостави продукт или услуга. При вече установени търговски или
          професионални отношения с Банката, предоставянето на лични данни може да представлява
          договорно или законово изискване. В тези случаи непредоставянето на данните може да доведе
          до прекратяване на сключения договор или на установените търговски отношения.
        </p>
        <p>
          Последиците от непредоставяне на съгласие са посочени в документа, в който се дава
          съгласието или се посочват изрично от Банката преди даване на съгласието.
        </p>
        <p>7.2. Банката не разглежда анонимни жалби/искания/предложения.</p>
        <p>
          8.1. Субектите на данни имат право на достъп, право да поискат коригиране, изтриване или
          ограничаване обработването на отнасящите се до тях и обработвани от Банката лични данни.
        </p>
        <p>
          8.2. Субектите на данни имат право да получат данните от Банката във вида и по начина,
          определени в закона и да ги прехвърлят на друг администратор. Субектите на данни имат
          право да поискат от Банката пряко да прехвърли данните им на друг администратор, когато
          това е технически осъществимо.
        </p>
        <p>
          8.3. Субектите на данни, чиито лични данни се обработват, могат по всяко време да възразят
          срещу обработването на данните им, когато се обработват на основание реализиране на права
          и интереси на Банката, които имат обосновано преимущество пред интересите на физическите
          лица, или за целите на директния маркетинг, както и да оттеглят дадените от тях съгласия,
          по един от следните начини:
        </p>
        <p>1. Чрез обаждане на тел. 0700 10 375</p>
        <p>2. Чрез изпращане на e-mail: call_center@dskbank.bg</p>
        <p>3. Във всяко едно поделение на Банката.</p>
        <p>
          Оттеглянето на даденото съгласие не засяга законосъобразността на обработването на личните
          данни преди оттеглянето. Въпреки оттегляне на съгласието, личните данни могат да бъдат
          обработвани от Банката за други цели, ако е налице основание за обработване на данните по
          т. 4, различно от съгласието.
        </p>
        <p>
          8.4. Физическите лица могат да упражнят правата си на посочените в т. 1 (Длъжностно лице
          по защита на личните данни) и т. 8.3. адреси и телефони за кореспонденция след надлежна
          идентификация.
        </p>
        <p>
          9. Физическите лица могат да упражнят правото си на жалба до Комисията за защита на
          личните данни, която е надзорен орган в областта на защитата на лични данни.
        </p>
        <p>Комисия за защита на личните данни:</p>
        <p>Адрес: София 1592, бул. &bdquo;Проф. Цветан Лазаров&rdquo; №2</p>
        <p>Електронна поща: kzld@cpdp.bg</p>
        <p>Интернет страница: www.cpdp.bg</p>
        <p>10. Банка ДСК съхранява събраните лични данни в следните срокове:</p>
        <p>
          а) когато данните се обработват на основание искане за ползване на продукт/услуга - за
          максимален срок до три месеца от подаване на искането за сключване на договор, ако
          искането не бъде одобрено;
        </p>
        <p>
          б) когато данните се обработват на основание сключен договор - за срок от 5 г. от началото
          на календарната година, следваща годината на прекратяването на отношенията; при
          прехвърляне на вземане &ndash; 5 г. след погасяването на вземането към цесионера, но не
          по-рано от 5 г. от окончателно приключване на всички съдебни производства, свързани с
          него; 5 г. от началото на календарната година, следваща годината на прекратяването на
          отношенията между Банката и купувача на недвижимия имот - в случаите на обработване
          данните на продавач на недвижим имот;
        </p>
        <p>
          в) когато данните се обработват на основание получено съгласие - до оттегляне на
          съгласието;
        </p>
        <p>
          г) когато данните се обработват за реализиране на права и интереси на Банката, които имат
          обосновано преимущество пред интересите на физическите лица &ndash; до погасяване на
          правото и/или отпадане на интереса;
        </p>
        <p>д) срок от 5 г. след предоставяне на съответната еднократна услуга;</p>
        <p>е) срок от 3 г. след изготвяне на отговор на подадени жалба/искане/предложение;</p>
        <p>
          ж) срок от 5 г. от началото на календарната година, следваща годината на прекратяването на
          отношенията между Банката и лицето, което е рефинансирало чуждо кредитно задължение - в
          случаите на обработване на данните на физически лица, чиито кредитни задължения са били
          рефинансирани.
        </p>
        <p>з) максимален срок от 6 години за записи на разговори с Контактен център.</p>
        <p>и) срок от 2 месеца за записи от осъществено видеонаблюдение.</p>
        <p>
          След изтичане на посочените срокове, ако не е налице друго основание за обработване на
          данните, те ще бъдат изтрити. С цел получаване и анализиране на информация, свързана с
          ползвани продукти и услуги, както и подобряване на обслужването, Банката може да изтрие
          само част от данните. В тези случаи тя продължава да съхранява такава част от данните,
          която не позволява физическите лица да могат да бъдат последващо идентифицирани.
        </p>
      </div>
    </div>
  );
}

export default PersonalDataConsent;
