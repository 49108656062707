import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import useStyles from './error-dialog.styles';
import Error from '../error/error.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import { setErrorOccured } from '../../providers/application/application.actions';

const ErrorDialog = () => {
  const classes = useStyles();

  const { errorOccured } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const handleClose = () => {
    dispatch(setErrorOccured(false));
  };

  return (
    <Dialog open={errorOccured} onClose={handleClose}>
      <DialogContent className={classes.dialogContent}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Error />
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
