import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headerImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '0 0 2.5rem 2.5rem',
    '@media (max-width:1120px)': {
      borderRadius: '0 0 0 0',
    },
  },
  headerContainer: {
    paddingLeft: '0px',
    paddingRight: '0px',
    '@media (min-width:1336px)': {
      minHeight: '550px',
    },
    '@media (min-width:768px) and (max-width:1336px)': {
      minHeight: 'calc(550px - (1336px - 100vw) * 0.35)',
    },
    '@media (min-width:440px) and (max-width:768px)': {
      minHeight: 'calc(435px - (768px - 100vw) * 0.48)',
    },
    '@media (max-width:440px)': {
      minHeight: 'calc(500px - (440px - 100vw) * 0.92)',
    },
    '@media screen and (-ms-high-contrast: none) and (max-width:1336px)': {
      minHeight: 'calc(550px - (1336px - 100vw) * 0.36)',
    },
  },
  headerContentContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    maxWidth: '1120px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '23px',
  },
  topMenuItemCenter: {
    color: '#ffffff',
    marginTop: '8px',
    '@media (max-width:520px)': {
      marginTop: '3px',
    },
  },
  topMenuItemRight: {
    color: '#ffffff',
    cursor: 'pointer',
    textAlign: 'right',
  },
  topMenuItemLeft: {
    color: '#ffffff',
  },
  dskLogoHeader: {
    color: '#ffffff',
    cursor: 'pointer',
    '@media (max-width:520px)': {
      width: '150px',
    },
  },
  headerDivider: {
    color: '#ffffff',
    marginRight: '10px',
    marginLeft: '10px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  languageIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '5px',
    fontSize: '1.2rem',
  },
}));

export default useStyles;
