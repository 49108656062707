import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  badge: {
    width: '167px',
  },
  badgeWithPadding: {
    width: '167px',
    paddingLeft: '16px !important',
  },
  badgeWrappingDiv: {
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-12px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
