import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'form-request-submit-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './translations/i18n';
import './index.css';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
