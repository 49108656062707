import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    paddingTop: '14px',
    background: 'transparent !important',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '250px',
    minHeight: '15px',
  },
  dialogTitle: {
    marginRight: '35px',
  },
}));

export default useStyles;
