const ApplicationActionTypes = {
  RESET_APPLICATION_STATE: 'RESET_APPLICATION_STATE',
  GO_TO_PREVIOUS_STEP: 'GO_TO_PREVIOUS_STEP',
  SET_ACCEPTED_PERSONAL_DATA_CONSENT: 'SET_ACCEPTED_PERSONAL_DATA_CONSENT',
  SAVE_CLIENT_DATA_START: 'SAVE_CLIENT_DATA_START',
  SAVE_CLIENT_DATA_SUCCESS: 'SAVE_CLIENT_DATA_SUCCESS',
  RELOAD_REQUIRED_FAILURE: 'RELOAD_REQUIRED_FAILURE',
  GENERAL_SERVER_FAILURE: 'GENERAL_SERVER_FAILURE',
  GENERATE_VERIFICATION_CODE_START: 'GENERATE_VERIFICATION_CODE_START',
  GENERATE_VERIFICATION_CODE_SUCCESS: 'GENERATE_VERIFICATION_CODE_SUCCESS',
  SET_ACCEPTED_REMOTE_FINANCIAL_SERVICES_CONSENT: 'SET_ACCEPTED_REMOTE_FINANCIAL_SERVICES_CONSENT',
  SET_IS_OTHER_BANK_SERVICES_SELECTED: 'SET_IS_OTHER_BANK_SERVICES_SELECTED',
  SET_IS_OTHER_CASH_ORIGINS_SELECTED: 'SET_IS_OTHER_CASH_ORIGINS_SELECTED',
  CHANGE_GENERIC_CONSENT: 'CHANGE_GENERIC_CONSENT',
  CHANGE_AUTOCOMPLETE_FIELD: 'CHANGE_AUTOCOMPLETE_FIELD',
  SAVE_PRODUCT_DATA_START: 'SAVE_PRODUCT_DATA_START',
  SAVE_PRODUCT_DATA_SUCCESS: 'SAVE_PRODUCT_DATA_SUCCESS',
  SAVE_GENERAL_TERMS_DATA_START: 'SAVE_GENERAL_TERMS_DATA_START',
  SAVE_GENERAL_TERMS_DATA_SUCCESS: 'SAVE_GENERAL_TERMS_DATA_SUCCESS',
  SAVE_ADDITIONAL_DATA_START: 'SAVE_ADDITIONAL_DATA_START',
  SAVE_ADDITIONAL_DATA_SUCCESS: 'SAVE_ADDITIONAL_DATA_SUCCESS',
  SAVE_DELIVERY_DATA_START: 'SAVE_DELIVERY_DATA_START',
  SAVE_DELIVERY_DATA_SUCCESS: 'SAVE_DELIVERY_DATA_SUCCESS',
  SAVE_IDENTIFICATION_DATA_START: 'SAVE_IDENTIFICATION_DATA_START',
  SAVE_IDENTIFICATION_DATA_SUCCESS: 'SAVE_IDENTIFICATION_DATA_SUCCESS',
  SAVE_CONTRACT_DATA_START: 'SAVE_CONTRACT_DATA_START',
  SAVE_CONTRACT_DATA_SUCCESS: 'SAVE_CONTRACT_DATA_SUCCESS',
  SET_LANGUAGE: 'SET_LANGUAGE',
  START_SESSION_START: 'LOAD_NOMENCLATURES_START',
  START_SESSION_SUCCESS: 'LOAD_NOMENCLATURES_SUCCESS',
  SET_ERROR_OCCURED: 'SET_ERROR_OCCURED',
};

export default ApplicationActionTypes;
