import React, { useEffect, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import {
  saveGeneralTermsDataAsync,
  generateVerificationCodeAsync,
} from '../../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import useStyles from './general-terms-step.styles';
import { requiredTextField } from '../../../utils/validation.utils';

const GeneralTermsStep = ({ formRef, backRef }) => {
  const { register, errors, handleSubmit, reset, getValues } = useForm();
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    smsVerificationCode,
    generatedVerificationCode,
    mobilePhoneConfirmed,
    verificationCodeValidityInMinutes,
    wrongSmsVerificationCode,
    maxSmsVerificationCodeAttemptsReached,
    expiredSmsVerificationCode,
    generateSmsVerificationCodeLoading,
    shouldGenerateVerificationCode,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isSuccessfullyRegenerated, setIsSuccessfullyRegenerated] = React.useState(false);

  const onSubmit = (data) => {
    saveGeneralTermsDataAsync(data, dispatch);
  };

  const generateNewCode = () => {
    reset({ smsVerificationCode: '' });
    generateVerificationCodeAsync(null, dispatch, (successfullyRegenerated) =>
      setIsSuccessfullyRegenerated(successfullyRegenerated)
    );
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return getValues();
    },
  }));

  useEffect(() => {
    if (shouldGenerateVerificationCode) {
      generateNewCode();
      setIsSuccessfullyRegenerated(false);
    }
  }, []);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box margin='normal'>
            {t('general-terms-step.sms-verification-code-was-send', {
              verificationCodeValidityInMinutes,
            })}
            <p>{t('general-terms-step.sms-verification-label')}</p>
          </Box>
          <Grid container>
            <TextField
              fullWidth={false}
              margin='normal'
              label={t('general-terms-step.sms-verification-code')}
              name='smsVerificationCode'
              defaultValue={smsVerificationCode}
              disabled={mobilePhoneConfirmed || generateSmsVerificationCodeLoading}
              error={
                !!wrongSmsVerificationCode ||
                !!expiredSmsVerificationCode ||
                !!maxSmsVerificationCodeAttemptsReached ||
                !!errors.smsVerificationCode
              }
              helperText={
                (errors.smsVerificationCode && errors.smsVerificationCode.message) ||
                (expiredSmsVerificationCode && (
                  <Trans>{t('validation.expired-sms-verification-code')}</Trans>
                )) ||
                (maxSmsVerificationCodeAttemptsReached && (
                  <Trans>{t('validation.max-sms-verification-code-attempts-reached')}</Trans>
                )) ||
                (wrongSmsVerificationCode && (
                  <Trans>{t('validation.wrong-sms-verification-code')}</Trans>
                ))
              }
              inputRef={register({
                validate: requiredTextField,
              })}
            />
            <div className={classes.buttonOuterWrapper}>
              <div className={classes.buttonInnerWrapper}>
                <Button
                  disabled={mobilePhoneConfirmed || generateSmsVerificationCodeLoading}
                  onClick={generateNewCode}
                >
                  {t('general-terms-step.generate-code-button')}
                </Button>
                {generateSmsVerificationCodeLoading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
              {isSuccessfullyRegenerated && (
                <Box className={classes.containerSuccess} fontWeight='fontWeightBold'>
                  <DoneIcon className='doneIcon' />
                </Box>
              )}
            </div>
          </Grid>
          {generatedVerificationCode && (
            <p className='checkboxErrorMessage'>{generatedVerificationCode}</p>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralTermsStep;
