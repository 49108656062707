import React from 'react';
import Error from '../error/error.component';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccured: false };
  }

  static getDerivedStateFromError() {
    return { errorOccured: true };
  }

  render() {
    const { errorOccured } = this.state;
    const { children } = this.props;

    if (errorOccured) {
      return <Error />;
    }

    return children;
  }
}

export default ErrorBoundary;
