import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@material-ui/core';
import useStyles from './identification-app.styles';
import AppStoreBadge from '../../../assets/images/apple.png';
import GooglePlayStore from '../../../assets/images/google.png';

const IdentificationAppBadges = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <span>
      <div className={classes.badgeWrappingDiv}>
        <a
          href='https://play.google.com/store/apps/details?id=com.evrotrust.app'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            alt={t('identification-step.google-play-img-alt')}
            src={GooglePlayStore}
            className={classes.badge}
          />
        </a>
      </div>
      <div className={classes.badgeWrappingDiv}>
        <a
          href='https://apps.apple.com/bg/app/evrotrust/id1195807317'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            alt={t('identification-step.apple-store-img-alt')}
            src={AppStoreBadge}
            className={fullScreen ? classes.badge : classes.badgeWithPadding}
          />
        </a>
      </div>
    </span>
  );
};

export default IdentificationAppBadges;
