import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  subsection: {
    marginTop: '8px',
    marginLeft: '9px',
    borderLeft: '1px solid #bdbdbd',
    paddingLeft: '20px',
    paddingRight: '8px',
  },
  remoteFinancialServicesConsentLink: {
    color: '#52ae30',
  },
  buttonOuterWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonInnerWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  containerSuccess: {
    display: 'flex',
    color: '#52ae30',
    padding: '6px 8px',
  },
}));

export default useStyles;
