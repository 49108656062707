import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepContentInner: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  actionsContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonForwardOuterWrapper: {
    display: 'inline',
    alignItems: 'center',
  },
  buttonForwardInnerWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  stepper: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px 8px 8px',
    },
  },
  infoIcon: {
    paddingLeft: '4px',
    top: '3px',
    position: 'relative',
    color: '#52ae30',
    cursor: 'pointer',
  },
  initialInfo: {
    paddingLeft: '30px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '0px',
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
      marginBottom: '10px',
      paddingLeft: '15px',
    },
  },
  initialInfoMain: {
    marginBottom: '3px',
  },
  infoListItem: {
    paddingLeft: '8px',
  },
  doneIcon: {
    color: '#52ae30',
    fontSize: '18px',
    marginRight: '5px',
    marginBottom: '-3px',
  },
}));

export default useStyles;
