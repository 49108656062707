import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import { saveIdentificationDataAsync } from '../../../providers/application/application.actions';
import IdentificationAppBadges from '../identification-app/identification-app.component';
import InfoDialog from '../../info-dialog/info-dialog.component';
import EvrotrustLogo from '../../../assets/images/evrotrust.png';
import useStyles from './identification-step.styles';

const IdentificationStep = ({ formRef }) => {
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const { t } = useTranslation();

  const { requestId, isForEvrotrustUser } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [showEvrotrustIdentificationDialog, setShowEvrotrustIdentificationDialog] = React.useState(
    false
  );

  const onSubmit = () => {
    saveIdentificationDataAsync(requestId, dispatch).then((response) =>
      setShowEvrotrustIdentificationDialog(response && response.isClientIdentified === false)
    );
  };

  const handleEvrotrustIdentificationDialogClose = () => {
    setShowEvrotrustIdentificationDialog(false);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div className={classes.evrotrustLogoText}>
              {t('identification-step.use-evrotrust-app')}
            </div>
            <div className={classes.evrotrustLogoWrappingDiv}>
              <img
                className={classes.evrotrustLogoImg}
                alt={t('identification-step.evrotrust-logo')}
                src={EvrotrustLogo}
              />
            </div>
          </Grid>
          <div className={classes.identificationSectionTitle}>
            {t('identification-step.use-evrotrust-app.line1')}
          </div>
          <Grid item xs={12} className={classes.identificationAppsSection}>
            <p>{t('identification-step.use-evrotrust-app.line1.step1')}</p>
          </Grid>
          <div className={classes.identificationSectionTitle}>
            {t('identification-step.use-evrotrust-app.line2')}
          </div>
          <Grid item xs={12} className={classes.identificationAppsSection}>
            <p>{t('identification-step.use-evrotrust-app.line2.step1')}</p>
            <IdentificationAppBadges />
            <p>{t('identification-step.use-evrotrust-app.line2.step2')}</p>
            <p>{t('identification-step.use-evrotrust-app.line2.step3')}</p>
            <p>{t('identification-step.use-evrotrust-app.line2.step4')}</p>
          </Grid>
        </Grid>
      </Grid>
      <InfoDialog
        open={showEvrotrustIdentificationDialog}
        title={t('identification-step.use-evrotrust-app-title')}
        handleClose={handleEvrotrustIdentificationDialogClose}
      >
        <Grid item xs={12}>
          {isForEvrotrustUser ? (
            <div>
              <Trans>{t('identification-step.evrotrust-user')}</Trans>
            </div>
          ) : (
            <div>
              <p className={classes.identificationLabel}>
                <Trans>{t('identification-step.not-evrotrust-user')}</Trans>
              </p>
              <IdentificationAppBadges />
            </div>
          )}
          <p>{t('identification-step.request-is-in-process')}</p>
        </Grid>
      </InfoDialog>
    </form>
  );
};

export default IdentificationStep;
