import i18n from 'i18next';
import ApplicationActionTypes from './application.types';
import { fetchGetJSON, fetchPostJSON } from '../../utils/fetch.utils';

const simulateAPIRequest = (fn) => {
  setTimeout(function triggerDispatch() {
    fn();
  }, 1000);
};

export const reloadRequiredFailure = (data) => ({
  type: ApplicationActionTypes.RELOAD_REQUIRED_FAILURE,
  payload: data,
});

export const generalServerFailure = (data) => ({
  type: ApplicationActionTypes.GENERAL_SERVER_FAILURE,
  payload: data,
});

const handleFetchError = (dispatch) => (error) => {
  if (error === 400) {
    dispatch(
      reloadRequiredFailure({
        reloadRequiredMessage: i18n.t('error.reload-required-message.new-version'),
        reloadRequiredButtonText: i18n.t('error.reload-required-button-text.new-version'),
      })
    );
  } else if (error === 401) {
    dispatch(
      reloadRequiredFailure({
        reloadRequiredMessage: i18n.t('error.reload-required-message.authentication'),
        reloadRequiredButtonText: i18n.t('error.reload-required-button-text.authentication'),
      })
    );
  } else {
    dispatch(generalServerFailure());
  }
};

export const resetApplicationState = () => ({
  type: ApplicationActionTypes.RESET_APPLICATION_STATE,
});

export const goToPreviousStep = (currentStepData) => ({
  type: ApplicationActionTypes.GO_TO_PREVIOUS_STEP,
  payload: currentStepData,
});

export const changeLanguage = (language) => ({
  type: ApplicationActionTypes.SET_LANGUAGE,
  payload: { language },
});

export const setErrorOccured = (errorOccured) => ({
  type: ApplicationActionTypes.SET_ERROR_OCCURED,
  payload: { errorOccured },
});

export const setAcceptedPersonalDataConsent = (acceptedPersonalDataConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT,
  payload: { acceptedPersonalDataConsent },
});

export const setAcceptedRemoteFinancialServicesConsent = (
  acceptedRemoteFinancialServicesConsent
) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_REMOTE_FINANCIAL_SERVICES_CONSENT,
  payload: { acceptedRemoteFinancialServicesConsent },
});

export const saveClientDataStart = () => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_START,
});

export const saveClientDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS,
  payload: data,
});

export const saveClientDataAsync = (data, dispatch) => {
  dispatch(saveClientDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveClientData`, data)
    .then((response) => {
      dispatch(saveClientDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const generateVerificationCodeStart = () => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START,
});

export const generateVerificationCodeSuccess = (data) => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS,
  payload: data,
});

export const generateVerificationCodeAsync = (data, dispatch, callbackFn) => {
  dispatch(generateVerificationCodeStart());

  return fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GenerateVerificationCode`
  )
    .then((response) => {
      dispatch(generateVerificationCodeSuccess(response));
      callbackFn(!response.requestCannotContinue);
    })
    .catch(handleFetchError(dispatch));
};

export const changeGenericConsent = (data) => ({
  type: ApplicationActionTypes.CHANGE_GENERIC_CONSENT,
  payload: data,
});

export const saveProductDataStart = () => ({
  type: ApplicationActionTypes.SAVE_PRODUCT_DATA_START,
});

export const saveProductDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_PRODUCT_DATA_SUCCESS,
  payload: data,
});

export const saveProductDataAsync = (data, dispatch) => {
  dispatch(saveProductDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveProductData`, data)
    .then((response) => {
      dispatch(saveProductDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveGeneralTermsDataStart = () => ({
  type: ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_START,
});

export const saveGeneralTermsDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_SUCCESS,
  payload: data,
});

export const saveGeneralTermsDataAsync = (data, dispatch) => {
  dispatch(saveGeneralTermsDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveGeneralTerms`, data)
    .then((response) => {
      dispatch(saveGeneralTermsDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveAdditionalDataStart = () => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_DATA_START,
});

export const saveAdditionalDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_DATA_SUCCESS,
  payload: data,
});

export const saveAdditionalDataAsync = (data, dispatch) => {
  dispatch(saveAdditionalDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveAdditionalData`, data)
    .then((response) => {
      dispatch(saveAdditionalDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveDeliveryDataStart = () => ({
  type: ApplicationActionTypes.SAVE_DELIVERY_DATA_START,
});

export const saveDeliveryDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_DELIVERY_DATA_SUCCESS,
  payload: data,
});

export const saveDeliveryDataAsync = (data, dispatch) => {
  dispatch(saveDeliveryDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveDeliveryData`, data)
    .then((response) => {
      dispatch(saveDeliveryDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveIdentificationDataStart = () => ({
  type: ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_START,
});

export const saveIdentificationDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_SUCCESS,
  payload: data,
});

export const saveIdentificationDataAsync = (data, dispatch) => {
  dispatch(saveIdentificationDataStart());

  return fetchPostJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveIdentificationData`,
    data
  )
    .then((response) => {
      dispatch(saveIdentificationDataSuccess({ ...data, ...response }));
      return response;
    })
    .catch(handleFetchError(dispatch));
};

export const saveContractDataStart = () => ({
  type: ApplicationActionTypes.SAVE_CONTRACT_DATA_START,
});

export const saveContractDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CONTRACT_DATA_SUCCESS,
  payload: data,
});

export const saveContractDataAsync = (requestId, dispatch) => {
  dispatch(saveContractDataStart());
  simulateAPIRequest(() => dispatch(saveContractDataSuccess()));
};

export const startSessionStart = () => ({
  type: ApplicationActionTypes.START_SESSION_START,
});

export const startSessionSuccess = (data) => ({
  type: ApplicationActionTypes.START_SESSION_SUCCESS,
  payload: data,
});

export const startSessionAsync = (queryString, dispatch, redirectFn) => {
  dispatch(startSessionStart());

  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/Get${queryString}`)
    .then((response) => {
      dispatch(startSessionSuccess(response));
      redirectFn();
    })
    .catch(handleFetchError(dispatch));
};

export const signOut = () => {
  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/SignOut`);
};
