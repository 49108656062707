import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './error.styles';
import ErrorIcon from '../../assets/images/error_icon.svg';

const Error = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justify='center'>
      <Grid className={classes.gridItem} item xs={12}>
        <img src={ErrorIcon} alt='' />
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {t('error.error-title')}
        </Typography>
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h6' variant='h6'>
          {t('error.error-subtitle')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Error;
