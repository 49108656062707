import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveClientDataAsync,
  setAcceptedPersonalDataConsent,
  setAcceptedRemoteFinancialServicesConsent,
} from '../../../providers/application/application.actions';
import ConsentDialog from '../../consent-dialog/consent-dialog.component';
import PhoneNumber from '../phone-number/phone-number.component';
import useStyles from './client-data-step.styles';
import { isValidEGN, requiredTextField } from '../../../utils/validation.utils';
import RemoteServicesConsent from '../../consents/remote-services-consent/remote-services.consent';
import PersonalDataConsent from '../../consents/personal-data-consent/personal-data-consent.component';

const ClientDataStep = ({ formRef }) => {
  const { register, errors, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    requestNumber,
    clientIdentifier,
    emailAddress,
    emailConfirmed,
    mobilePhoneCountry,
    mobilePhoneNumber,
    mobilePhoneOperator,
    mobilePhoneConfirmed,
    acceptedPersonalDataConsent,
    acceptedRemoteFinancialServicesConsent,
    nomenclatures: { mobilePhoneOperators, mobilePhoneCodes, countryBulgaria },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isBulgariaCountryCodeSelected, setIsBulgariaCountryCodeSelected] = React.useState(
    mobilePhoneCountry === countryBulgaria
  );
  const handleCountryCodeChange = (code) => {
    setIsBulgariaCountryCodeSelected(code === countryBulgaria);
  };

  const [showPersonalDataConsentDialog, setShowPersonalDataConsentDialog] = React.useState(false);

  const handlePersonalDataConsentChange = () => {
    if (acceptedPersonalDataConsent) {
      dispatch(setAcceptedPersonalDataConsent(false));
    } else {
      setShowPersonalDataConsentDialog(true);
    }
  };

  const handlePersonalDataConsentDialogClose = () => {
    setShowPersonalDataConsentDialog(false);
  };

  const handlePersonalDataConsentDialogConfirm = () => {
    dispatch(setAcceptedPersonalDataConsent(true));
    setShowPersonalDataConsentDialog(false);
  };

  const handlePersonalDataConsentDialogReject = () => {
    dispatch(setAcceptedPersonalDataConsent(false));
    setShowPersonalDataConsentDialog(false);
  };

  const [
    showRemoteFinancialServicesConsentDialog,
    setShowRemoteFinancialServicesConsentDialog,
  ] = React.useState(false);

  const handleRemoteFinancialServicesConsentChange = () => {
    if (acceptedRemoteFinancialServicesConsent) {
      dispatch(setAcceptedRemoteFinancialServicesConsent(false));
    } else {
      setShowRemoteFinancialServicesConsentDialog(true);
    }
  };

  const handleRemoteFinancialServicesConsentDialogClose = () => {
    setShowRemoteFinancialServicesConsentDialog(false);
  };

  const handleRemoteFinancialServicesConsentDialogConfirm = () => {
    dispatch(setAcceptedRemoteFinancialServicesConsent(true));
    setShowRemoteFinancialServicesConsentDialog(false);
  };

  const handleRemoteFinancialServicesConsentDialogReject = () => {
    dispatch(setAcceptedRemoteFinancialServicesConsent(false));
    setShowRemoteFinancialServicesConsentDialog(false);
  };

  const onSubmit = (data) => {
    const phoneNumber = isBulgariaCountryCodeSelected
      ? data.mobilePhoneNumber.replace(/^0+/, '')
      : data.mobilePhoneNumber;

    saveClientDataAsync({ ...data, mobilePhoneNumber: phoneNumber, requestNumber }, dispatch);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('client-data-step.client-identifier')}
            name='clientIdentifier'
            defaultValue={clientIdentifier}
            disabled={emailConfirmed}
            autoFocus
            inputProps={{
              maxLength: 10,
            }}
            error={!!errors.clientIdentifier}
            helperText={errors.clientIdentifier && errors.clientIdentifier.message}
            inputRef={register({
              validate: {
                requiredTextField,
                validEGN: (value) => isValidEGN(value) || t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('client-data-step.email-address')}
            name='emailAddress'
            type='email'
            defaultValue={emailAddress}
            disabled={emailConfirmed}
            inputProps={{
              maxLength: 40,
            }}
            error={!!errors.emailAddress}
            helperText={errors.emailAddress && errors.emailAddress.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/,
                message: t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneNumber
            options={mobilePhoneCodes}
            selectedOption={mobilePhoneCountry}
            selectedOptionOnChange={(code) => handleCountryCodeChange(code)}
            selectorName='mobilePhoneCountry'
            selectorValidation={{
              validate: (value) => !!value || t('validation.mobile-phone-code.required'),
            }}
            defaultValue={mobilePhoneNumber}
            propertyName='mobilePhoneNumber'
            inputProps={{
              maxLength: isBulgariaCountryCodeSelected ? 10 : 14,
            }}
            inputRef={register({
              validate: {
                requiredTextField,
                validPhoneNumber: (value) => {
                  return (
                    (isBulgariaCountryCodeSelected
                      ? /^[0-9]{9}$/.test(value.replace(/^0+/, ''))
                      : /^[0-9]{1,14}$/.test(value)) || t('validation.text-field.invalid')
                  );
                },
              },
            })}
            errors={errors}
            control={control}
            disabled={mobilePhoneConfirmed}
          />
        </Grid>
        {isBulgariaCountryCodeSelected && (
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              label={t('client-data-step.mobile-phone-operator')}
              name='mobilePhoneOperator'
              defaultValue={mobilePhoneOperator}
              disabled={mobilePhoneConfirmed}
              select
              error={!!errors.mobilePhoneOperator}
              helperText={errors.mobilePhoneOperator && errors.mobilePhoneOperator.message}
              rules={{
                required: {
                  value: true,
                  message: t('validation.select-field.required'),
                },
              }}
              as={
                <TextField>
                  {mobilePhoneOperators.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          </Grid>
        )}
        {!isBulgariaCountryCodeSelected && (
          <p className={classes.foreignNumberText}>{t('client-data-step.foreign-number-text')}</p>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name='acceptedPersonalDataConsent'
                checked={acceptedPersonalDataConsent}
                onClick={handlePersonalDataConsentChange}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.checkbox-field.required'),
                  },
                })}
              />
            }
            label={
              <span>
                {t('client-data-step.accepted-personal-data-consent')}{' '}
                <span className={classes.personalDataConsentLink}>
                  {t('client-data-step.accepted-personal-data-consent-link')}
                </span>
              </span>
            }
          />
          {errors.acceptedPersonalDataConsent && !acceptedPersonalDataConsent && (
            <p className='checkboxErrorMessage'>{errors.acceptedPersonalDataConsent.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name='acceptedRemoteFinancialServicesConsent'
                checked={acceptedRemoteFinancialServicesConsent}
                onClick={handleRemoteFinancialServicesConsentChange}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.checkbox-field.required'),
                  },
                })}
              />
            }
            label={
              <span>
                {t('general-terms-step.accepted-remote-financial-services-consent')}{' '}
                <span className={classes.remoteFinancialServicesConsentLink}>
                  {t('general-terms-step.accepted-remote-financial-services-consent-link')}
                </span>
              </span>
            }
          />
          {errors.acceptedRemoteFinancialServicesConsent &&
            !acceptedRemoteFinancialServicesConsent && (
              <p className='checkboxErrorMessage'>
                {errors.acceptedRemoteFinancialServicesConsent.message}
              </p>
            )}
        </Grid>
      </Grid>
      <p>{t('general-terms-step.info')}</p>
      <ConsentDialog
        open={showPersonalDataConsentDialog}
        handleClose={handlePersonalDataConsentDialogClose}
        handleConfirm={handlePersonalDataConsentDialogConfirm}
        handleReject={handlePersonalDataConsentDialogReject}
      >
        <PersonalDataConsent />
      </ConsentDialog>
      <ConsentDialog
        open={showRemoteFinancialServicesConsentDialog}
        handleClose={handleRemoteFinancialServicesConsentDialogClose}
        handleConfirm={handleRemoteFinancialServicesConsentDialogConfirm}
        handleReject={handleRemoteFinancialServicesConsentDialogReject}
      >
        <RemoteServicesConsent />
      </ConsentDialog>
    </form>
  );
};

export default ClientDataStep;
