import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#52ae30',
    },
    secondary: {
      main: '#52ae30',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 996,
      lg: 1336,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji"`,
    h1: {
      fontFamily: `"Squad",ui-sans-serif,system-ui,-system-ui,-apple-system,BlinkMacSystemFont,
        "Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: 30,
        textTransform: 'none',
        padding: '.75rem 2.5rem',
      },
      containedPrimary: {
        color: 'white',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#74bf1e',
          boxShadow: 'none',
        },
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      label: {
        fontSize: 16,
      },
    },
    MuiAutocomplete: {
      input: {
        '&:first-child': {
          padding: '9.5px 14px !important',
        },
      },
      clearIndicator: {
        display: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 30,
        paddingLeft: '18px !important',
        paddingRight: '18px !important',
      },
    },
    PrivateNotchedOutline: {
      root: {
        paddingLeft: 25,
        paddingRight: 25,
      },
    },
    MuiInputLabel: {
      formControl: {
        left: 18,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 4px 40px #95959529',
      },
      elevation2: {
        boxShadow: '0 1rem 3rem rgba(73,92,136,0.15)',
      },
    },
    MuiStepIcon: {
      text: {
        fill: '#ffffff',
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: 0,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '16px',
        fontWeight: 700,
        '&.MuiStepLabel-active': {
          fontWeight: 700,
        },
        '&.MuiStepLabel-completed': {
          fontWeight: 700,
        },
      },
      iconContainer: {
        paddingRight: '10px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '16px 12px 16px 12px',
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: '4px',
        paddingLeft: '14px',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'start',
      },
    },
    MuiCheckbox: {
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
    },
    MuiRadio: {
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 32,
      },
    },
    MuiChip: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        backgroundColor: '#ffffff',
        color: '#000000',
      },
      deleteIcon: {
        color: '#52ae30',
        '&:hover': {
          color: '#74bf1e',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
      autoComplete: 'off',
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiButton: {
      disableRipple: true,
      color: 'primary',
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
});

export default theme;
