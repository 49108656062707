import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { useTheme, useMediaQuery } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import useStyles from './consent-dialog.styles';

const ConsentDialog = ({ open, title, handleClose, handleConfirm, handleReject, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>
        {title}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button autoFocus onClick={handleReject} color='primary' className={classes.rejectButton}>
          Отказ
        </Button>
        <Button onClick={handleConfirm} variant='contained' color='primary'>
          Запознат съм
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
