import React from 'react';
import applicationReducer, { initialState } from './application.reducer';

const ApplicationStateContext = React.createContext();
const ApplicationDispatchContext = React.createContext();

function ApplicationStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(applicationReducer, initialState);

  return (
    <ApplicationStateContext.Provider value={state}>
      <ApplicationDispatchContext.Provider value={dispatch}>
        {children}
      </ApplicationDispatchContext.Provider>
    </ApplicationStateContext.Provider>
  );
}

function useApplicationState() {
  const context = React.useContext(ApplicationStateContext);
  if (context === undefined) {
    throw new Error('useApplicationState must be used within an ApplicationProvider');
  }
  return context;
}

function useApplicationDispatch() {
  const context = React.useContext(ApplicationDispatchContext);
  if (context === undefined) {
    throw new Error('useApplicationDispatch must be used within an ApplicationProvider');
  }
  return context;
}

export { ApplicationStateProvider, useApplicationState, useApplicationDispatch };
