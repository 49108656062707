import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: 'rgba(0, 0, 0, 0.23)',
  },
  active: {
    color: '#52ae30',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    marginLeft: '8px',
    marginTop: '2px',
  },
  completed: {
    color: '#52ae30',
    zIndex: 1,
    fontSize: 18,
    marginLeft: '5px',
    marginRight: '-5px',
    marginBottom: '-3px',
  },
}));

export default useStyles;
