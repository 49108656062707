import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderRadius: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  contentContainer: {
    maxWidth: '1120px',
    marginTop: '-120px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    minHeight: '100vh',
    paddingLeft: '0',
    paddingRight: '0',
    position: 'relative',
  },
}));

export default useStyles;
