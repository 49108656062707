import React from 'react';
import { useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from './request.styles';
import ClientDataStep from '../../components/request/client-data-step/client-data-step.component';
import IdentificationStep from '../../components/request/identification-step/identification-step.component';
import GeneralTermsStep from '../../components/request/general-terms-step/general-terms-step.component';
import AdditionalDataStep from '../../components/request/additional-data-step/additional-data-step.component';
import DeliveryDataStep from '../../components/request/delivery-data-step/delivery-data-step.component';
import RequestSuccess from '../../components/request/request-success/request-success.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import { goToPreviousStep } from '../../providers/application/application.actions';
import RequestInfo from '../../components/request/request-info/request-info.component';
import StepIcon from '../../components/request/step-icon/step-icon.component';
import InfoDialog from '../../components/info-dialog/info-dialog.component';
import ReloadRequiredPrompt from '../../components/reload-required-prompt/reload-required-prompt.component';

function getSteps() {
  return [
    { name: 'Лични данни', nameEN: 'ClientData' },
    { name: 'Потвърждаване', nameEN: 'GeneralTerms' },
    { name: 'Идентификация с Evrotrust', nameEN: 'Identification' },
    { name: 'Допълнителна информация', nameEN: 'AdditionalData' },
    { name: 'Адрес за доставка на картата', nameEN: 'DeliveryData' },
  ];
}

function getStepContent(step, formRefs, backRefs) {
  switch (step) {
    case 0:
      return <ClientDataStep formRef={formRefs[step]} />;
    case 1:
      return <GeneralTermsStep formRef={formRefs[step]} backRef={backRefs[step]} />;
    case 2:
      return <IdentificationStep formRef={formRefs[step]} backRef={backRefs[step]} />;
    case 3:
      return <AdditionalDataStep formRef={formRefs[step]} backRef={backRefs[step]} />;
    case 4:
      return <DeliveryDataStep formRef={formRefs[step]} backRef={backRefs[step]} />;
    default:
      return '';
  }
}

function Request() {
  const classes = useStyles();
  const steps = getSteps();
  const { t } = useTranslation();
  const history = useHistory();

  const formRefs = steps.map(() => React.createRef());
  const backRefs = steps.map(() => React.createRef());

  const {
    activeStep,
    loading,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    isSessionStarted,
    emailConfirmed,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const handleNext = () => {
    const formRef = formRefs[activeStep];
    if (formRef && formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleBack = () => {
    const backRef = backRefs[activeStep];
    if (backRef && backRef.current) {
      dispatch(goToPreviousStep(backRef.current.getValues()));
    } else {
      dispatch(goToPreviousStep());
    }
  };

  const [showAdditionalDataInfoDialog, setAdditionalDataInfoDialog] = React.useState(false);

  const handleAdditionalDataInfoDialogOpen = () => {
    setAdditionalDataInfoDialog(true);
  };

  const handleAdditionalDataInfoClose = () => {
    setAdditionalDataInfoDialog(false);
  };

  if (!isSessionStarted) {
    history.push('/');
    return <div />;
  }

  if (activeStep >= steps.length) {
    return (
      <div className={classes.root}>
        <RequestSuccess />
      </div>
    );
  }

  if (requestCannotContinue || confirmationEmailPending || resumeEmailPending) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {!emailConfirmed && (
        <div className={classes.initialInfo}>
          <div className={classes.initialInfoMain}>{t('initial-info.main.header')}</div>
          <Box className={classes.infoListItem}>
            <DoneIcon className={classes.doneIcon} />
            {`${t('initial-info.line1')}`}
          </Box>
          <Box className={classes.infoListItem}>
            <DoneIcon className={classes.doneIcon} />
            {`${t('initial-info.line2')}`}
          </Box>
          <Box className={classes.infoListItem}>
            <DoneIcon className={classes.doneIcon} />
            {`${t('initial-info.line3')}`}
          </Box>
        </div>
      )}
      <Stepper activeStep={activeStep} orientation='vertical' className={classes.stepper}>
        {steps.map((step, index) => (
          <Step key={step.nameEN}>
            <StepLabel StepIconComponent={StepIcon}>
              {step.name}{' '}
              {index === activeStep && activeStep === 3 && (
                <span className={classes.infoIcon}>
                  <InfoOutlinedIcon
                    fontSize='inherit'
                    onClick={handleAdditionalDataInfoDialogOpen}
                  />
                </span>
              )}
            </StepLabel>
            <StepContent className={classes.stepContent}>
              <div className={classes.stepContentInner}>
                {getStepContent(index, formRefs, backRefs)}
              </div>
              <div className={classes.actionsContainer}>
                <div>
                  <Box component='span' display={{ xs: 'none', md: 'inline' }}>
                    {activeStep === 0 ? (
                      ''
                    ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {t('general.backward')}
                      </Button>
                    )}
                  </Box>
                  <span className={classes.buttonForwardOuterWrapper}>
                    <span className={classes.buttonForwardInnerWrapper}>
                      <Button
                        id={`btnForward_${step.nameEN}`}
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                        className={classes.button}
                        disabled={loading}
                      >
                        {activeStep === steps.length - 1
                          ? t('general.complete')
                          : t('general.forward')}
                      </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </span>
                  </span>
                  <Box component='span' display={{ xs: 'inline', md: 'none' }}>
                    {activeStep === 0 ? (
                      ''
                    ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {t('general.backward')}
                      </Button>
                    )}
                  </Box>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <InfoDialog
        open={showAdditionalDataInfoDialog}
        title={t('additional-data.info-dialog-title')}
        handleClose={handleAdditionalDataInfoClose}
      >
        {t('additional-data.info-dialog-description')}
      </InfoDialog>
      <ReloadRequiredPrompt />
    </div>
  );
}

export default Request;
