import ApplicationActionTypes from './application.types';

export const initialState = {
  requestNumber: '',
  token: '',
  clientIdentifier: '',
  emailAddress: '',
  emailConfirmed: false,
  mobilePhoneCountry: '',
  mobilePhoneNumber: '',
  mobilePhoneOperator: null,
  mobilePhoneConfirmed: false,
  acceptedPersonalDataConsent: false,
  requestCannotContinue: false,
  clientNotFulfilledRequirementsForNewRequest: false,
  confirmationEmailPending: false,
  resumeEmailPending: false,
  acceptedRemoteFinancialServicesConsent: false,
  smsVerificationCode: '',
  generatedVerificationCode: '',
  shouldGenerateVerificationCode: false,
  verificationCodeValidityInMinutes: '',
  isForEvrotrustUser: false,
  isClientIdentified: false,
  selectedJobPositionId: '',
  isCorrespondenceAndResidenceAddressSameAsPermanentAddress: true,
  permanentAddressCountry: null,
  permanentAddressProvince: '',
  permanentAddressCountryMunicipality: '',
  permanentAddressCountrySettlement: '',
  permanentAddressCountryLocation: '',
  permanentAddressCountryPostCode: '',
  correspondenceAddressCountry: null,
  correspondenceAddressProvince: '',
  correspondenceAddressCountryMunicipality: '',
  correspondenceAddressCountrySettlement: '',
  correspondenceAddressCountryLocation: '',
  correspondenceAddressCountryPostCode: '',
  residenceAddressCountry: null,
  residenceAddressProvince: '',
  residenceAddressCountryMunicipality: '',
  residenceAddressCountrySettlement: '',
  residenceAddressCountryLocation: '',
  residenceAddressCountryPostCode: '',
  requestIsForBulgarianCitizenOnly: true,
  selectedBankServices: [],
  selectedCashOrigins: [],
  selectedJobPositions: [],
  requestIsForNonPoliticallyExposedPerson: '',
  debitCardCardholderName: '',
  debitCardDeliveryMethod: '',
  debitCardDeliveryAddressType: '',
  debitCardDeliveryBranch: null,
  deliveryMethodAddress: null,
  deliveryMethodBranch: null,
  deliveryAddressPostCode: '',
  otherDebitCardDeliveryAddressCountry: null,
  otherDebitCardDeliveryAddressProvince: '',
  otherDebitCardDeliveryAddressCountryMunicipality: '',
  otherDebitCardDeliveryAddressCountrySettlement: '',
  otherDebitCardDeliveryAddressCountryLocation: '',
  otherDebitCardDeliveryAddressCountryPostCode: '',
  activeStep: 0,
  loading: false,
  language: 'bg',
  errorOccured: false,
  reloadRequired: false,
  wrongSmsVerificationCode: false,
  expiredSmsVerificationCode: false,
  maxSmsVerificationCodeAttemptsReached: false,
  generateSmsVerificationCodeLoading: false,
  allAddresses: [],
  nomenclatures: {
    mobilePhoneOperators: [],
    mobilePhoneCodes: [],
    countries: [],
    bankServices: [],
    cashOrigins: [],
    bankServiceOther: '',
    deliveryMethods: [],
    branches: [],
    addressTypeOther: '',
    countryBulgaria: '',
    jobPositions: [],
  },
  isSessionStarted: false,
  requestTermsUrl: '',
  notFulfilledRequirementsUrl: '',
};

function applicationReducer(state, action) {
  switch (action.type) {
    case ApplicationActionTypes.RESET_APPLICATION_STATE: {
      return { ...initialState };
    }
    case ApplicationActionTypes.GO_TO_PREVIOUS_STEP: {
      return {
        ...state,
        ...action.payload,
        activeStep: state.activeStep - 1,
      };
    }
    case ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS: {
      return { ...state, ...action.payload, activeStep: state.activeStep + 1, loading: false };
    }
    case ApplicationActionTypes.GENERAL_SERVER_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        errorOccured: true,
      };
    }
    case ApplicationActionTypes.RELOAD_REQUIRED_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        reloadRequired: true,
      };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START: {
      return { ...state, generateSmsVerificationCodeLoading: true };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        generateSmsVerificationCodeLoading: false,
      };
    }
    case ApplicationActionTypes.SET_ACCEPTED_REMOTE_FINANCIAL_SERVICES_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_IS_OTHER_BANK_SERVICES_SELECTED: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_IS_OTHER_CASH_ORIGINS_SELECTED: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.CHANGE_GENERIC_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.CHANGE_AUTOCOMPLETE_FIELD: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_PRODUCT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_PRODUCT_DATA_SUCCESS: {
      return { ...state, ...action.payload, activeStep: state.activeStep + 1, loading: false };
    }
    case ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStep: action.payload.mobilePhoneConfirmed ? state.activeStep + 1 : state.activeStep,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_DATA_SUCCESS: {
      return { ...state, ...action.payload, activeStep: state.activeStep + 1, loading: false };
    }
    case ApplicationActionTypes.SAVE_DELIVERY_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_DELIVERY_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStep: action.payload.requestCannotContinue ? state.activeStep : state.activeStep + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStep: action.payload.isClientIdentified ? state.activeStep + 1 : state.activeStep,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_CONTRACT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CONTRACT_DATA_SUCCESS: {
      return {
        ...initialState,
        ...action.payload,
        activeStep: state.activeStep + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SET_LANGUAGE: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.START_SESSION_START: {
      return { ...state };
    }
    case ApplicationActionTypes.START_SESSION_SUCCESS: {
      return { ...state, ...action.payload, isSessionStarted: true };
    }
    case ApplicationActionTypes.SET_ERROR_OCCURED: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default applicationReducer;
