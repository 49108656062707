import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  identificationAppsSection: {
    paddingLeft: '15px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  evrotrustAppLine: {
    marginLeft: '15px',
  },
  identificationLabel: {
    marginTop: '0px !important',
  },
  identificationSectionTitle: {
    fontWeight: 'bold',
    marginTop: '10px',
  },
  evrotrustLogoText: {
    display: 'inline',
  },
  evrotrustLogoWrappingDiv: {
    display: 'inline',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  evrotrustLogoImg: {
    position: 'absolute',
    top: '-19px',
    left: '4px',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
}));

export default useStyles;
