import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  subsection: {
    marginTop: '8px',
    marginLeft: '9px',
    borderLeft: '1px solid #bdbdbd',
    paddingLeft: '20px',
    paddingRight: '8px',
  },
  radioControlPadding: {
    paddingBottom: '10px',
  },
  capitalizedText: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  postCodeSection: {
    marginBottom: '15px',
  },
}));

export default useStyles;
