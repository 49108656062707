import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      width: '50% !important',
    },
  },
  innerSelect: {
    '& .MuiInputBase-root': {
      paddingLeft: '0px !important',
      marginLeft: '-14px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    width: '100%',
  },
  inputAdornment: {
    width: '50%',
  },
}));

export default useStyles;
