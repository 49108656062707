/* eslint-disable react/jsx-props-no-spreading */
import React, { useImperativeHandle } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  changeGenericConsent,
  saveAdditionalDataAsync,
} from '../../../providers/application/application.actions';
import useStyles from './additional-data-step.styles';
import { requiredTextField } from '../../../utils/validation.utils';
import CustomPopper from '../custom-popper/custom-popper.component';

const AdditionalDataStep = ({ formRef, backRef }) => {
  const { register, handleSubmit, errors, control, getValues } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isCorrespondenceAndResidenceAddressSameAsPermanentAddress,
    permanentAddressProvince,
    permanentAddressCountryMunicipality,
    permanentAddressCountrySettlement,
    permanentAddressCountryLocation,
    permanentAddressCountryPostCode,
    correspondenceAddressCountry,
    correspondenceAddressProvince,
    correspondenceAddressCountryMunicipality,
    correspondenceAddressCountrySettlement,
    correspondenceAddressCountryLocation,
    correspondenceAddressCountryPostCode,
    residenceAddressCountry,
    residenceAddressProvince,
    residenceAddressCountryMunicipality,
    residenceAddressCountrySettlement,
    residenceAddressCountryLocation,
    residenceAddressCountryPostCode,
    requestIsForBulgarianCitizenOnly,
    selectedBankServices,
    selectedCashOrigins,
    selectedJobPositions,
    requestIsForNonPoliticallyExposedPerson,
    nomenclatures: { countries, bankServices, cashOrigins, countryBulgaria, jobPositions },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const constructFormData = (data) => {
    return {
      ...data,
      selectedBankServiceIds: data.selectedBankServices.map((bs) => bs.id),
      selectedCashOriginIds: data.selectedCashOrigins.map((cs) => cs.id),
      selectedJobPositionIds: data.selectedJobPositions.map((jp) => jp.id),
      isCorrespondenceAndResidenceAddressSameAsPermanentAddress,
      requestIsForBulgarianCitizenOnly,
      requestIsForNonPoliticallyExposedPerson,
    };
  };

  /* NOTE: We get radiogroup values from state, because in the submitted data they are strings */
  const onSubmit = (data) => {
    saveAdditionalDataAsync(constructFormData(data), dispatch);
  };

  const handleChange = (e) => {
    dispatch(changeGenericConsent({ [e.target.name]: e.target.value === 'true' }));
  };

  const resetAddressSubsection = (permanentAddressData) => {
    const result = {};

    Object.keys(permanentAddressData).forEach((key) => {
      const correspondenceKey = key.replace('permanent', 'correspondence');
      result[correspondenceKey] = permanentAddressData[key];

      const residenceKey = key.replace('permanent', 'residence');
      result[residenceKey] = permanentAddressData[key];
    });

    return result;
  };

  const handleAddressChange = (e) => {
    let data = {
      [e.target.name]: e.target.value === 'true',
    };

    if (e.target.value !== 'true') {
      const initialData = resetAddressSubsection({
        permanentAddressProvince,
        permanentAddressCountryMunicipality,
        permanentAddressCountrySettlement,
        permanentAddressCountryLocation,
        permanentAddressCountryPostCode:
          permanentAddressCountryPostCode !== '0000' ? permanentAddressCountryPostCode : null,
      });

      data = { ...data, ...initialData };
    }

    dispatch(changeGenericConsent(data));
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name='selectedJobPositions'
            defaultValue={selectedJobPositions}
            render={(props) => (
              <Autocomplete
                noOptionsText={t('general.autocomplete-no-options')}
                multiple
                PopperComponent={CustomPopper}
                value={props.value}
                options={jobPositions}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => !!value.id && value.id === option.id}
                onChange={(_, value) => {
                  props.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('additional-data-step.employment-position')}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.selectedJobPositions}
                    helperText={errors.selectedJobPositions && errors.selectedJobPositions.message}
                    placeholder={t('general.add-employment-position')}
                  />
                )}
              />
            )}
            control={control}
            rules={{
              validate: (value) => value.length > 0 || t('validation.select-field.required'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div>{t('additional-data-step.permanent-address')}</div>
          <div className={classes.displayDiv}>
            {`${[permanentAddressCountrySettlement, permanentAddressCountryLocation]
              .filter((v) => v)
              .join(', ')}`}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('general.post-code')}
            name='permanentAddressCountryPostCode'
            defaultValue={permanentAddressCountryPostCode}
            inputProps={{
              maxLength: 4,
            }}
            error={!!errors.permanentAddressCountryPostCode}
            helperText={
              errors.permanentAddressCountryPostCode &&
              errors.permanentAddressCountryPostCode.message
            }
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: /^[1-9][0-9]{3}$/,
                message: t('validation.invalid-bg-post-code', { count: '4' }),
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <span>{t('additional-data-step.correspondence-address-same-as-permanent-address')}</span>
          <RadioGroup
            name='isCorrespondenceAndResidenceAddressSameAsPermanentAddress'
            value={isCorrespondenceAndResidenceAddressSameAsPermanentAddress}
            row
          >
            <FormControlLabel
              inputRef={register}
              name='isCorrespondenceAndResidenceAddressSameAsPermanentAddress'
              value
              onClick={handleAddressChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register}
              name='isCorrespondenceAndResidenceAddressSameAsPermanentAddress'
              value={false}
              onClick={handleAddressChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {!isCorrespondenceAndResidenceAddressSameAsPermanentAddress && (
            <div className={classes.subsection}>
              <div className={classes.subsectionLabel}>
                {t('additional-data-step.correspondence-address')}
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='correspondenceAddressCountry'
                    defaultValue={correspondenceAddressCountry}
                    render={(props) => (
                      <Autocomplete
                        noOptionsText={t('general.autocomplete-no-options')}
                        value={props.value}
                        PopperComponent={CustomPopper}
                        disabled
                        options={countries.filter((country) => country.id === countryBulgaria)}
                        getOptionLabel={(option) =>
                          option.name ||
                          countries.find((country) => country.id === option).name ||
                          ''
                        }
                        getOptionSelected={(option, value) => option.id === value}
                        onChange={(_, value) => props.onChange(value && value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('general.country')}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.correspondenceAddressCountry}
                            helperText={
                              errors.correspondenceAddressCountry &&
                              errors.correspondenceAddressCountry.message
                            }
                          />
                        )}
                      />
                    )}
                    control={control}
                    rules={{
                      validate: (value) => !!value || t('validation.select-field.required'),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.district')}
                    name='correspondenceAddressProvince'
                    defaultValue={correspondenceAddressProvince}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.correspondenceAddressProvince}
                    helperText={
                      errors.correspondenceAddressProvince &&
                      errors.correspondenceAddressProvince.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.municipality')}
                    name='correspondenceAddressCountryMunicipality'
                    defaultValue={correspondenceAddressCountryMunicipality}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.correspondenceAddressCountryMunicipality}
                    helperText={
                      errors.correspondenceAddressCountryMunicipality &&
                      errors.correspondenceAddressCountryMunicipality.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.territorial-unit')}
                    name='correspondenceAddressCountrySettlement'
                    defaultValue={correspondenceAddressCountrySettlement}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.correspondenceAddressCountrySettlement}
                    helperText={
                      errors.correspondenceAddressCountrySettlement &&
                      errors.correspondenceAddressCountrySettlement.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.address')}
                    name='correspondenceAddressCountryLocation'
                    defaultValue={correspondenceAddressCountryLocation}
                    inputProps={{
                      maxLength: 100,
                    }}
                    error={!!errors.correspondenceAddressCountryLocation}
                    helperText={
                      errors.correspondenceAddressCountryLocation &&
                      errors.correspondenceAddressCountryLocation.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.post-code')}
                    name='correspondenceAddressCountryPostCode'
                    defaultValue={correspondenceAddressCountryPostCode}
                    inputProps={{
                      maxLength: 4,
                    }}
                    error={!!errors.correspondenceAddressCountryPostCode}
                    helperText={
                      errors.correspondenceAddressCountryPostCode &&
                      errors.correspondenceAddressCountryPostCode.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: /^[1-9][0-9]{3}$/,
                        message: t('validation.invalid-bg-post-code', { count: '4' }),
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <div className={classes.addressSubsectionLabel}>
                {t('additional-data-step.residence-address')}
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='residenceAddressCountry'
                    defaultValue={residenceAddressCountry}
                    render={(props) => (
                      <Autocomplete
                        noOptionsText={t('general.autocomplete-no-options')}
                        value={props.value}
                        PopperComponent={CustomPopper}
                        disabled
                        options={countries.filter((country) => country.id === countryBulgaria)}
                        getOptionLabel={(option) =>
                          option.name ||
                          countries.find((country) => country.id === option).name ||
                          ''
                        }
                        getOptionSelected={(option, value) => option.id === value}
                        onChange={(_, value) => props.onChange(value && value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('general.country')}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.residenceAddressCountry}
                            helperText={
                              errors.residenceAddressCountry &&
                              errors.residenceAddressCountry.message
                            }
                          />
                        )}
                      />
                    )}
                    control={control}
                    rules={{
                      validate: (value) => !!value || t('validation.select-field.required'),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.district')}
                    name='residenceAddressProvince'
                    defaultValue={residenceAddressProvince}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.residenceAddressProvince}
                    helperText={
                      errors.residenceAddressProvince && errors.residenceAddressProvince.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.municipality')}
                    name='residenceAddressCountryMunicipality'
                    defaultValue={residenceAddressCountryMunicipality}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.residenceAddressCountryMunicipality}
                    helperText={
                      errors.residenceAddressCountryMunicipality &&
                      errors.residenceAddressCountryMunicipality.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.territorial-unit')}
                    name='residenceAddressCountrySettlement'
                    defaultValue={residenceAddressCountrySettlement}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.residenceAddressCountrySettlement}
                    helperText={
                      errors.residenceAddressCountrySettlement &&
                      errors.residenceAddressCountrySettlement.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.address')}
                    name='residenceAddressCountryLocation'
                    defaultValue={residenceAddressCountryLocation}
                    inputProps={{
                      maxLength: 100,
                    }}
                    error={!!errors.residenceAddressCountryLocation}
                    helperText={
                      errors.residenceAddressCountryLocation &&
                      errors.residenceAddressCountryLocation.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('general.post-code')}
                    name='residenceAddressCountryPostCode'
                    defaultValue={residenceAddressCountryPostCode}
                    inputProps={{
                      maxLength: 4,
                    }}
                    error={!!errors.residenceAddressCountryPostCode}
                    helperText={
                      errors.residenceAddressCountryPostCode &&
                      errors.residenceAddressCountryPostCode.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: /^[1-9][0-9]{3}$/,
                        message: t('validation.invalid-bg-post-code', { count: '4' }),
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <span>{t('additional-data-step.local-person-for-tax-purposes')}</span>
          <RadioGroup
            name='requestIsForBulgarianCitizenOnly'
            value={requestIsForBulgarianCitizenOnly}
            row
          >
            <FormControlLabel
              inputRef={register}
              name='requestIsForBulgarianCitizenOnly'
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register}
              name='requestIsForBulgarianCitizenOnly'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {!requestIsForBulgarianCitizenOnly && (
            <p className='radioButtonsErrorMessage'>
              {t('local-person-for-tax-purposes.invalid-choice')}
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '12px' }}>
            {t('additional-data-step.additional-bank-services')}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='selectedBankServices'
                defaultValue={selectedBankServices}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    multiple
                    PopperComponent={CustomPopper}
                    value={props.value}
                    options={bankServices}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => !!value.id && value.id === option.id}
                    onChange={(_, value) => {
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('additional-data-step.bank-services')}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.selectedBankServices}
                        helperText={
                          errors.selectedBankServices && errors.selectedBankServices.message
                        }
                        placeholder={t('general.add-bank-service')}
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => value.length > 0 || t('validation.select-field.required'),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '12px' }}>{t('additional-data-step.cash-origins')}</div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='selectedCashOrigins'
                defaultValue={selectedCashOrigins}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    multiple
                    PopperComponent={CustomPopper}
                    value={props.value}
                    options={cashOrigins}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => !!value.id && value.id === option.id}
                    onChange={(_, value) => {
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('additional-data-step.cash-origins-label')}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.selectedCashOrigins}
                        helperText={
                          errors.selectedCashOrigins && errors.selectedCashOrigins.message
                        }
                        placeholder={t('general.add-cash-origin')}
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => value.length > 0 || t('validation.select-field.required'),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <span>{t('additional-data-step.public-person')}</span>
          <RadioGroup
            name='requestIsForNonPoliticallyExposedPerson'
            value={requestIsForNonPoliticallyExposedPerson}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.select-field.required'),
                },
              })}
              name='requestIsForNonPoliticallyExposedPerson'
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.select-field.required'),
                },
              })}
              name='requestIsForNonPoliticallyExposedPerson'
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
            />
          </RadioGroup>
          {errors.requestIsForNonPoliticallyExposedPerson && (
            <p className='radioButtonsErrorMessage'>
              {errors.requestIsForNonPoliticallyExposedPerson.message}
            </p>
          )}
          {requestIsForNonPoliticallyExposedPerson === false && (
            <p className='radioButtonsErrorMessage'>
              {t('politically-exposed-person.invalid-choice')}
            </p>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default AdditionalDataStep;
