import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  subsection: {
    marginTop: '8px',
    marginLeft: '9px',
    borderLeft: '1px solid #bdbdbd',
    paddingLeft: '20px',
    paddingRight: '8px',
  },
  displayDiv: {
    marginTop: '10px',
    fontWeight: '600',
  },
  link: {
    color: '#52ae30',
    cursor: 'pointer',
  },
  subsectionLabel: {
    marginBottom: '15px',
  },
  addressSubsectionLabel: {
    marginTop: '24px',
    marginBottom: '15px',
  },
}));

export default useStyles;
