/* eslint-disable react/jsx-props-no-spreading */
import React, { useImperativeHandle } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import {
  changeGenericConsent,
  saveDeliveryDataAsync,
} from '../../../providers/application/application.actions';
import useStyles from './delivery-data.styles';
import { requiredTextField } from '../../../utils/validation.utils';
import CustomPopper from '../custom-popper/custom-popper.component';
import { filterOptions } from '../../../utils/common.utils';

const DeliveryDataStep = ({ formRef, backRef }) => {
  const { handleSubmit, control, register, errors, getValues } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    nomenclatures: { deliveryMethods, countries, addressTypeOther, countryBulgaria, branches },
    debitCardCardholderName,
    debitCardDeliveryMethod,
    debitCardDeliveryAddressType,
    deliveryMethodAddress,
    deliveryMethodBranch,
    allAddresses,
    otherDebitCardDeliveryAddressCountry,
    otherDebitCardDeliveryAddressProvince,
    otherDebitCardDeliveryAddressCountryMunicipality,
    otherDebitCardDeliveryAddressCountrySettlement,
    otherDebitCardDeliveryAddressCountryLocation,
    otherDebitCardDeliveryAddressCountryPostCode,
    debitCardDeliveryBranch,
    mobilePhoneCountry,
    deliveryAddressPostCode,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [
    selectedDebitCardDeliveryAddressType,
    setSelectedDebitCardDeliveryAddressType,
  ] = React.useState(debitCardDeliveryAddressType);

  const constructFormData = (data) => {
    return {
      ...data,
      debitCardDeliveryAddressType,
    };
  };

  const onSubmit = (data) => {
    saveDeliveryDataAsync(constructFormData(data), dispatch);
  };

  const handleChange = (e, postCode, addressTypeId) => {
    setSelectedDebitCardDeliveryAddressType(postCode === '0000' ? addressTypeId : null);
    dispatch(changeGenericConsent({ [e.target.name]: Number(e.target.value) }));
  };

  const formatedAddress = (countryName, municipality, territorialUnit, address, postCode) => {
    return t('delivery-data-step.address-format', {
      country: countryName,
      municipality,
      territorialUnit,
      address,
      postCode: postCode !== '0000' ? postCode : '',
    });
  };

  const [isDeliveryMethodBranchSelected, setIsDeliveryMethodBranchSelected] = React.useState(
    debitCardDeliveryMethod === deliveryMethodBranch
  );

  const [isDeliveryMethodAddressSelected, setIsDeliveryMethodAddressSelected] = React.useState(
    debitCardDeliveryMethod === deliveryMethodAddress
  );

  const handleDeliveryMethodChange = (deliveryMethod) => {
    setIsDeliveryMethodBranchSelected(deliveryMethod === deliveryMethodBranch);
    setIsDeliveryMethodAddressSelected(deliveryMethod === deliveryMethodAddress);
  };

  const isBulgarianMobilePhoneSelected = mobilePhoneCountry === countryBulgaria;

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3} style={{ paddingRight: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.capitalizedText}
              name='debitCardCardholderName'
              label={t('delivery-data-step.latin-name')}
              disabled
              defaultValue={debitCardCardholderName}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} style={{ paddingRight: 0 }}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name='debitCardDeliveryMethod'
              defaultValue={debitCardDeliveryMethod}
              render={(props) => (
                <TextField
                  label={t('delivery-data-step.delivery-type')}
                  select
                  disabled={!isBulgarianMobilePhoneSelected}
                  value={props.value || ''}
                  onChange={(e) => {
                    handleDeliveryMethodChange(e.target.value);
                    props.onChange(e.target.value || '');
                  }}
                  error={!!errors.debitCardDeliveryMethod}
                  helperText={
                    errors.debitCardDeliveryMethod && errors.debitCardDeliveryMethod.message
                  }
                >
                  {deliveryMethods.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t('validation.select-field.required'),
                },
              }}
            />
          </Grid>
        </Grid>
        {isDeliveryMethodAddressSelected && (
          <Grid item xs={12}>
            <span>{t('delivery-data-step.select-address')}</span>
            <RadioGroup name='debitCardDeliveryAddressType' value={debitCardDeliveryAddressType}>
              {allAddresses
                .filter((a) => a.typeId !== addressTypeOther)
                .map((address) => {
                  return (
                    <div key={address.typeId}>
                      <FormControlLabel
                        inputRef={register({
                          required: {
                            value: true,
                            message: t('validation.select-field.required'),
                          },
                        })}
                        name='debitCardDeliveryAddressType'
                        value={address.typeId}
                        onClick={(e) => handleChange(e, address.postCode, address.typeId)}
                        control={<Radio />}
                        label={formatedAddress(
                          address.countryName,
                          address.municipality,
                          address.settlement,
                          address.location,
                          address.postCode
                        )}
                        className={classes.radioControlPadding}
                      />
                      {address.typeId === selectedDebitCardDeliveryAddressType &&
                        address.postCode === '0000' && (
                          <div className={classes.subsection}>
                            <Grid item xs={12} sm={6} className={classes.postCodeSection}>
                              <TextField
                                label={t('general.post-code')}
                                name='deliveryAddressPostCode'
                                defaultValue={deliveryAddressPostCode}
                                inputProps={{
                                  maxLength: 4,
                                }}
                                error={!!errors.deliveryAddressPostCode}
                                helperText={
                                  errors.deliveryAddressPostCode &&
                                  errors.deliveryAddressPostCode.message
                                }
                                inputRef={register({
                                  validate: requiredTextField,
                                  pattern: {
                                    value: /^[1-9][0-9]{3}$/,
                                    message: t('validation.invalid-bg-post-code', { count: '4' }),
                                  },
                                })}
                              />
                            </Grid>
                          </div>
                        )}
                    </div>
                  );
                })}
              <FormControlLabel
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.select-field.required'),
                  },
                })}
                name='debitCardDeliveryAddressType'
                value={addressTypeOther}
                onClick={handleChange}
                control={<Radio />}
                label={t('general.other')}
              />
            </RadioGroup>
            {errors.debitCardDeliveryAddressType && (
              <p className='radioButtonsErrorMessage'>
                {errors.debitCardDeliveryAddressType.message}
              </p>
            )}
            {debitCardDeliveryAddressType === addressTypeOther && (
              <div className={classes.subsection}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name='otherDebitCardDeliveryAddressCountry'
                      defaultValue={otherDebitCardDeliveryAddressCountry}
                      render={(props) => (
                        <Autocomplete
                          noOptionsText={t('general.autocomplete-no-options')}
                          value={props.value}
                          PopperComponent={CustomPopper}
                          disabled
                          options={countries.filter((country) => country.id === countryBulgaria)}
                          getOptionLabel={(option) =>
                            option.name ||
                            countries.find((country) => country.id === option).name ||
                            ''
                          }
                          getOptionSelected={(option, value) => option.id === value}
                          onChange={(_, value) => {
                            props.onChange(value && value.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('general.country')}
                              InputLabelProps={{ shrink: true }}
                              error={!!errors.otherDebitCardDeliveryAddressCountry}
                              helperText={
                                errors.otherDebitCardDeliveryAddressCountry &&
                                errors.otherDebitCardDeliveryAddressCountry.message
                              }
                            />
                          )}
                        />
                      )}
                      control={control}
                      rules={{
                        validate: (value) => !!value || t('validation.select-field.required'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('general.district')}
                      name='otherDebitCardDeliveryAddressProvince'
                      defaultValue={otherDebitCardDeliveryAddressProvince}
                      inputProps={{
                        maxLength: 40,
                      }}
                      error={!!errors.otherDebitCardDeliveryAddressProvince}
                      helperText={
                        errors.otherDebitCardDeliveryAddressProvince &&
                        errors.otherDebitCardDeliveryAddressProvince.message
                      }
                      inputRef={register({
                        validate: requiredTextField,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('general.municipality')}
                      name='otherDebitCardDeliveryAddressCountryMunicipality'
                      defaultValue={otherDebitCardDeliveryAddressCountryMunicipality}
                      inputProps={{
                        maxLength: 40,
                      }}
                      error={!!errors.otherDebitCardDeliveryAddressCountryMunicipality}
                      helperText={
                        errors.otherDebitCardDeliveryAddressCountryMunicipality &&
                        errors.otherDebitCardDeliveryAddressCountryMunicipality.message
                      }
                      inputRef={register({
                        validate: requiredTextField,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('general.territorial-unit')}
                      name='otherDebitCardDeliveryAddressCountrySettlement'
                      defaultValue={otherDebitCardDeliveryAddressCountrySettlement}
                      inputProps={{
                        maxLength: 40,
                      }}
                      error={!!errors.otherDebitCardDeliveryAddressCountrySettlement}
                      helperText={
                        errors.otherDebitCardDeliveryAddressCountrySettlement &&
                        errors.otherDebitCardDeliveryAddressCountrySettlement.message
                      }
                      inputRef={register({
                        validate: requiredTextField,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('general.address')}
                      name='otherDebitCardDeliveryAddressCountryLocation'
                      defaultValue={otherDebitCardDeliveryAddressCountryLocation}
                      inputProps={{
                        maxLength: 100,
                      }}
                      error={!!errors.otherDebitCardDeliveryAddressCountryLocation}
                      helperText={
                        errors.otherDebitCardDeliveryAddressCountryLocation &&
                        errors.otherDebitCardDeliveryAddressCountryLocation.message
                      }
                      inputRef={register({
                        validate: requiredTextField,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('general.post-code')}
                      name='otherDebitCardDeliveryAddressCountryPostCode'
                      defaultValue={otherDebitCardDeliveryAddressCountryPostCode}
                      inputProps={{
                        maxLength: 4,
                      }}
                      error={!!errors.otherDebitCardDeliveryAddressCountryPostCode}
                      helperText={
                        errors.otherDebitCardDeliveryAddressCountryPostCode &&
                        errors.otherDebitCardDeliveryAddressCountryPostCode.message
                      }
                      inputRef={register({
                        validate: requiredTextField,
                        pattern: {
                          value: /^[1-9][0-9]{3}$/,
                          message: t('validation.invalid-bg-post-code', { count: '4' }),
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        )}
        {(isDeliveryMethodAddressSelected || isDeliveryMethodBranchSelected) && (
          <Grid container item xs={12} spacing={3} style={{ paddingRight: 0 }}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='debitCardDeliveryBranch'
                defaultValue={debitCardDeliveryBranch}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    value={props.value}
                    PopperComponent={CustomPopper}
                    options={branches}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) =>
                      option.name || branches.find((branch) => branch.id === option).name || ''
                    }
                    getOptionSelected={(option, value) => option.id === value}
                    onChange={(_, value) => {
                      props.onChange(value && value.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          (isDeliveryMethodAddressSelected &&
                            t('delivery-data-step.service-branch')) ||
                          (isDeliveryMethodBranchSelected &&
                            t('delivery-data-step.delivery-branch'))
                        }
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.debitCardDeliveryBranch}
                        helperText={
                          errors.debitCardDeliveryBranch && errors.debitCardDeliveryBranch.message
                        }
                      />
                    )}
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => !!value || t('validation.select-field.required'),
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default DeliveryDataStep;
