import React from 'react';
import Check from '@material-ui/icons/Check';
import useStyles from './step-icon.styles';

const StepIcon = (props) => {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div className={active ? classes.active : classes.root}>
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
};

export default StepIcon;
