/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from './phone-number.styles';
import CustomPopper from '../custom-popper/custom-popper.component';

const PhoneNumber = (p) => {
  const classes = useStyles();
  const {
    options,
    selectedOption,
    selectedOptionOnChange,
    selectorName,
    selectorValidation,
    defaultValue,
    propertyName,
    inputProps,
    inputRef,
    errors,
    control,
    disabled,
  } = p;
  const { t } = useTranslation();

  return (
    <TextField
      label={t('client-data-step.mobile-phone-number')}
      className={classes.root}
      defaultValue={defaultValue}
      name={propertyName}
      disabled={disabled}
      onChange={(e) => {
        e.target.value = e.target.value.replace(/[^0-9]+/g, '');
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' className={classes.inputAdornment}>
            <Controller
              name={selectorName}
              defaultValue={selectedOption}
              render={(props) => (
                <Autocomplete
                  noOptionsText={t('general.autocomplete-no-options')}
                  disabled={disabled}
                  PopperComponent={CustomPopper}
                  className={classes.innerSelect}
                  value={props.value}
                  options={options}
                  getOptionLabel={(option) =>
                    option.name || options.find((o) => o.id === option).name || ''
                  }
                  getOptionSelected={(option, value) => !!value && option.id === value}
                  onChange={(_, value) => {
                    selectedOptionOnChange(value && value.id);
                    props.onChange(value && value.id);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              control={control}
              rules={selectorValidation}
            />
          </InputAdornment>
        ),
      }}
      inputProps={inputProps}
      error={!!errors.mobilePhoneNumber || !!errors.mobilePhoneCountry}
      helperText={
        (errors.mobilePhoneNumber && errors.mobilePhoneNumber.message) ||
        (errors.mobilePhoneCountry && errors.mobilePhoneCountry.message)
      }
      inputRef={inputRef}
    />
  );
};

export default PhoneNumber;
