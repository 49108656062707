import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Header from '../header/header.component';
import Footer from '../footer/footer.component';
import useStyles from './layout.styles';

const Layout = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth='lg' className={classes.mainContainer}>
        <Header />
        <Container className={classes.contentContainer}>
          <Paper className={classes.paper} elevation={1}>
            {children}
          </Paper>
        </Container>
        <Footer />
      </Container>
    </div>
  );
};

export default Layout;
