import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    background: 'transparent !important',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rejectButton: {
    padding: '.75rem 2.5rem',
  },
}));

export default useStyles;
