import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  gridItem: {
    textAlign: 'center',
  },
  buttonsSection: {
    paddingTop: '16px',
  },
}));

export default useStyles;
