import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme/theme';
import Layout from './components/layout/layout.component';
import Request from './pages/request/request.component';
import ErrorDialog from './components/error-dialog/error-dialog.component';
import ErrorBoundary from './components/error-boundary/error-boundary.component';
import { ApplicationStateProvider } from './providers/application/application.provider';
import StartSession from './pages/start-session/start-session.component';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApplicationStateProvider>
        <ErrorDialog />
        <Layout>
          <ErrorBoundary>
            <Switch>
              <Route exact path='/'>
                <StartSession />
              </Route>
              <Route exact path='/Request'>
                <Request />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Layout>
      </ApplicationStateProvider>
    </ThemeProvider>
  );
}

export default App;
