import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import useStyles from './request-info.styles';
import InfoIcon from '../../../assets/images/info_icon.svg';
import { useApplicationState } from '../../../providers/application/application.provider';
import { signOut } from '../../../providers/application/application.actions';

const RequestInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    requestCannotContinue,
    clientNotFulfilledRequirementsForNewRequest,
    confirmationEmailPending,
    resumeEmailPending,
    requestTermsUrl,
    notFulfilledRequirementsUrl,
    emailConfirmed,
  } = useApplicationState();

  let infoTitle = '';
  let infoSubtitle = '';

  useEffect(() => {
    if (emailConfirmed && requestCannotContinue) {
      signOut();
    }
  }, [emailConfirmed, requestCannotContinue]);

  const handleContinueClick = () => {
    window.location.href = notFulfilledRequirementsUrl;
  };

  if (requestCannotContinue) {
    infoTitle = t('request-info.title.request-cannot-continue');

    if (clientNotFulfilledRequirementsForNewRequest) {
      infoSubtitle = (
        <div>
          <div>{t('process-terminated.client-not-fulfilled-requirements.line1')}</div>
          <Box component='a' href={requestTermsUrl} target='_blank' rel='noopener noreferrer'>
            <b>{t('general.request-terms')}</b>
          </Box>
          <div>{t('process-terminated.client-not-fulfilled-requirements.line2')}</div>
          <div className={classes.buttonsSection}>
            <Button variant='contained' color='primary' onClick={handleContinueClick}>
              {t('continue')}
            </Button>
          </div>
        </div>
      );
    } else {
      infoSubtitle = <div>{t('request-info.title.request-cannot-continue.subtitle')}</div>;
    }
  } else if (confirmationEmailPending) {
    infoTitle = t('request-info.title.confirmation-email-pending');

    infoSubtitle = (
      <div>
        {t('request-info.subtitle.confirmation-email-pending.line1')}
        <br />
        {t('request-info.subtitle.confirmation-email-pending.line2')}
      </div>
    );
  } else if (resumeEmailPending) {
    infoTitle = t('request-info.title.resume-email-pending');

    infoSubtitle = <div>{t('request-info.title.resume-email-pending.line1')}</div>;
  }

  return (
    <Grid container spacing={2} justify='center'>
      <Grid className={classes.gridItem} item xs={12}>
        <img src={InfoIcon} alt='' />
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {infoTitle}
        </Typography>
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h6' variant='h6'>
          {infoSubtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RequestInfo;
