import React from 'react';

function RemoteServicesConsent() {
  return (
    <div className='consent consent-no-inline-padding'>
      <p align='center'>
        <b>Условия за предоставяне на финансови услуги от разстояние</b>
      </p>
      &bdquo;Банка ДСК&rdquo; АД е търговско дружество, регистрирано в Търговския регистър и
      регистъра за ЮЛНЦ към Агенция по вписванията с ЕИК 121830616. Седалище и адрес на управление:
      гр. София, ул. &bdquo;Московска&rdquo; №19. тел: 0700 10 375, факс: (02) 980 64 77; e-mail:
      <a href='mailto:call_center@dskbank.bg'> call_center@dskbank.bg</a>; BIC/SWIFT: STSABGSF.
      <p>
        &bdquo;Банка ДСК&rdquo; АД извършва банкова дейност въз основа на лиценз № Б 03, издаден от
        Българска народна банка, с адрес: гр. София, пл. &quot;Княз Александър І&quot; №1, която
        осъществява надзор върху дейността.
        <br />
        &bdquo;Банка ДСК&rdquo; АД извършва дейност по инвестиционно посредничество въз основа на
        решение № РГ-03-193 на Държавната комисия по ценни книжа, понастоящем Комисия за финансов
        надзор (КФН), с адрес: гр. София, ул. &bdquo;Будапеща&rdquo; № 16, която осъществява надзор
        върху дейността.
      </p>
      <p>
        Общото работно време за Банката с клиенти в официални за страната работни дни е от 8:00 до
        17:00 ч.
      </p>
      <p>
        <b>I. Сключване на договор за предоставяне на финансови услуги от разстояние</b>
      </p>
      <ol>
        <li>
          1. Банка ДСК сключва със свои клиенти - физически лица следните видове договори за
          предоставяне на финансови услуги от разстояние: Откриване и обслужване на разплащателна
          сметка в BGN, USD или EUR; Издаване и обслужване на дебитна карта, на Виртуална карта, на
          допълнителна кредитна карта; Кредити за текущо потребление; Достъп до електронни канали;
          Търговия с дялове на инвестиционни фондове.
        </li>
        <li>
          2. Преди подписването на някой от договорите по т.1 Банката предоставя на клиента
          дължимите по закон документи за съответния договор, с които Клиентът трябва да се
          запознае. Съгласието си с документите Клиентът отбелязва чрез маркиране на съответно
          обозначено място и/или чрез подписване с електронен подпис.
        </li>
        <li>
          3. Предоставената от Банката преддоговорна информация е валидна към датата на
          предоставянето ѝ. Параметрите на услугите, начинът на предоставянето им и дължимите от
          Клиента такси и комисиони за тях са описани в предоставената му преддоговорна информация
          за съответния вид услуга. Актуалните Общи условия за съответния продукт/услуга, Тарифа на
          &bdquo;Банка ДСК&rdquo; АД, както и информация относно обработването на лични данни на
          физически лица от &bdquo;Банка ДСК&rdquo; АД, могат да бъдат намерени на интернет
          страницата <a href='http://www.dskbank.bg'>www.dskbank.bg</a> и във всяко едно поделение
          на Банката. Клиентът не заплаща други допълнителни разходи, свързани с конкретния договор
          за услуга, сключен от разстояние.
        </li>
        <li>
          4. Договорите по т. 1 са договори при общи условия, като договорите, ведно с общите
          условия към тях и всички други свързани с договорите документи, се подписват от Клиента с
          електронен подпис по смисъла на Закона за електронния документ и електронните
          удостоверителни услуги.
        </li>
        <li>
          5. Договорът за избраната от Клиента услуга се смята за сключен след подписването му от
          страна на Клиента и Банката. Предоставянето на съответната услуга е възможно само след
          сключване на договора съгласно предходното изречение. Договорът, заедно с всички свързани
          с него документи, подписани от Клиента и/или Банката, са достъпни за Клиента в непроменяем
          вид за преглед, изтегляне и печат.
        </li>
        <li>
          6. Потребителят има право, без да дължи обезщетение или неустойка и без да посочва
          причина, да се откаже от сключения договор от разстояние по т. 1 (с изключение на договора
          за търговия с дялове на инвестиционни фондове) в срок до 14 дни, считано от датата на
          сключване на договора. Правото на отказ се смята за упражнено, при условие че Клиентът
          (лично или чрез нарочно упълномощено с нотариално заверено пълномощно лице) подаде писмено
          уведомление за това до Банката преди изтичане на 14-дневния срок на хартиен носител във
          всяко поделение на Банката.
        </li>
        <li>
          6.1. В 7-дневен срок от подаване на уведомлението Клиентът дължи заплащане на таксите,
          комисионните и други разходи за всички използвани от него услуги по договора, които са му
          предоставени преди изтичането на срока за упражняване на правото на отказ, въз основа на
          изрично предоставеното му съгласие с приемането на тези Условия, включително месечни такси
          за обслужване на банкови сметки, такси за издаване на банкова карта и такси за изпълнени
          платежни операции.
        </li>
        <li>
          6.2. В 30-дневен срок от изпращане на уведомлението за упражняване правото на отказ
          Клиентът дължи връщане на усвоения кредит, респективно - кредитен лимит чрез допълнителна
          кредитна карта и заплащане на договорената лихва и другите разходи, при условията на
          сключените договори за предоставяне на кредит за текущо потребление, респективно за
          издаване и обслужване на кредитна карта с револвиращ кредит. Правото се упражнява по реда
          и при условията, посочени в Общите условия за съответния вид кредит.
        </li>
        <li>
          6.3. Когато Клиентът упражни правото си на отказ от сключен договор или допълнително
          споразумение за издаване на банкова карта и ако е получил картата, трябва да върне
          пластиката при подаване на съответното уведомление за прекратяване на договор в срока по
          т.6.1.
        </li>
      </ol>
      <p>
        <b>II. Подаване на електронни искания за ползване на кредитни продукти</b>
      </p>
      <ol>
        <li>
          7. Банка ДСК приема искания в електронен вид за ползване на кредитни продукти. Въз основа
          на получено искане и посочените в него данни Банката може да даде предварително одобрение
          или отказ за предоставяне на искания кредитен продукт. Заявените продукти могат да се
          ползват само след подписване на съответните договори. Електронното искане има срок на
          валидност 14 календарни дни от дата на подаването.
        </li>
        <li>
          8. Предварителното одобрение за предоставяне на кредитен продукт е неангажиращо за Банката
          и няма характер на оферта. Когато искането е подадено през Интернет страницата на Банката
          или през електронните й канали, валидността на одобрението е 30 календарни дни от датата,
          на която Клиентът е бил уведомен за него, а когато е през платформата ДСК Хоум – 3 месеца.
          Ако последният ден от срока е неработен, то за краен срок се приема първият следващ
          работен ден. Сключването на договор за кредит за одобрения кредитен продукт може да бъде
          извършено в рамките на посочения срок. Банката предоставя дължимата по закон преддоговорна
          информация преди сключване на съответния договор за кредит.
        </li>
        <li>
          9. При заявено желание за ползване на кредит чрез банкова карта, картата ще може да се
          получи в заявен от Клиента банков офис след подписване на договора за нейното издаване и
          обслужване.
        </li>
        <li>
          10. При отказ за предоставяне на кредит, включително когато отказът е на база резултати от
          извършена проверка в Централния кредитен регистър или в друга база данни, информация за
          това ще бъде получена на посочен от Клиента телефонен номер, e-mail адрес и/или в
          регистрирания профил в Кредитния портал на Банката (при искане, подадено през онлайн
          платформата ДСК Хоум).
        </li>
        <li>
          11. С приемане на настоящите Условия Клиентът заявява, че е информиран, че за срока на
          валидност на подаденото по електронен път искане Банката обработва личните му данни с цел
          подготвителни действия за сключване на договор за кредит. След изтичане на този срок и, в
          случай че не е подписан договор, и не е налице друго основание за обработване на данните,
          те ще бъдат изтрити.
        </li>
        <li>
          12. Приложимо към отношенията, установявани между Клиента и Банката преди сключването от
          разстояние на договора за съответния вид услуга съгласно т. I или т. II., е относимото
          българско банково и общо законодателство, а спорове във връзка с тези отношения се
          разрешават от компетентния български съд.
        </li>
      </ol>
    </div>
  );
}

export default RemoteServicesConsent;
